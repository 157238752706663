.romoveInfoMainWrapper {
  display: none;
}

.infoWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 15px;
  margin-top: 70px;
  position: relative;
}

.infoRemoveBtn {
  position: absolute;
  top: -20px;
  right: 20px;
  cursor: pointer;
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.contentWrapper img {
  width: 28px;
  height: 28px;
}

.contentWrapper h6 {
  font-weight: 600;
  line-height: 24px;
  color: var(--primary_text_color);
  padding-bottom: 10px;
}

.contentWrapper p {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  padding-bottom: 10px;
}

.arrowImgWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 200px;
}

.arrowImg {
  margin-inline: 20px;
  flex: 0 0 40%;
  max-width: 40%;
}

.createBtnWrapper {
  flex: 1;
}

.arrowImg img {
  width: 100%;
}
.createBtnWrapper button,
.createBtnWrapper a {
  white-space: nowrap;
  padding: 10px 20px;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 50px;
  color: var(--heading_text_color);
  transition: 0.3s all ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border: 0;
}
.createBtnWrapper button:hover .createBtnWrapper a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 576px) {
  .arrowImgWrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  .arrowImg img {
    display: none;
  }
}
