.breadCrumb {
  display: flex;
  margin-top: 30px;
}
.breadCrumb a,
.breadCrumb span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary_text_color);
}
.cardsWrapper {
  background: var(--secondary_color);
  border-radius: 50px;
  padding: 20px;
  margin: 30px 0 50px 0;
}
.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 100px;
}
