.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}
.mobileViewWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.boxWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 40px;
  overflow: auto;
  min-height: 100vh;
}
.qrWrapper {
  border-radius: 20px;
  background-color: var(--primary_color);
  padding: 35px 20px 20px;
  text-align: center;
  margin-top: 1.5rem;
  position: relative;
}
.qrCodeInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrCode {
  position: relative;
  /* margin-bottom: 50px; */
  z-index: 1;
  /* background-image: url(../../../../assets/images/qr-code-border.svg); */
  width: 254px;
  height: 254px;
}
.qrCode img {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.qrCode canvas {
  border-radius: 10px;
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.btnWrapper button {
  border: 0px;
  /* background: linear-gradient(106.6deg, var(--primary_button_color_light) 0%, var(--primary_button_color) 99.84%); */
  background: transparent;
  color: var(--primary_button_color);
  border: 1px solid var(--primary_button_color);
  font-size: 14px;
  border-radius: 10px;
  font-weight: 600;
  padding: 10px 30px;
  /* box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4); */
  transition: all 0.3s ease;
  margin-top: 1rem;
}
.modalGradientCircle {
  filter: blur(25px);
  width: 100%;
  height: 150px;
  border-radius: 50%;
  background: radial-gradient(
    52.22% 52.22% at 50% 39.76%,
    var(--primary_button_color) 0%,
    rgba(34, 160, 129, 0) 100%
  );
  position: absolute;
  z-index: -1;
  top: -15px;
}
.contentText {
  font-size: 20px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
  color: #505b75;
}
.contentText a {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
  color: #505b75;
}
.action {
  position: absolute;
  right: 20px;
  top: 20px;
}
