.mainWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--heading_text_color);
}
.builderWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  min-height: 10vh;
  margin-bottom: 50px;
  padding: 25px;
}
.builderWrapper h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: var(--heading_text_color);
}
.preDefineComponentWrapper {
  border-top: 1px solid var(--border_color);
  border-bottom: 1px solid var(--border_color);
  padding: 20px 0 40px;
  margin: 15px 0;
}
.preDefineComponentWrapper h6,
.customComponentWrapper h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--heading_text_color);
  margin-bottom: 5px;
}
.preDefineComponentWrapper p,
.customComponentWrapper p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--heading_text_color);
  margin-bottom: 20px;
}
.preDefineComponentButton {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  color: var(--heading_text_color);
  padding: 10px 15px;
  border-radius: 36px;
  width: 100%;
  text-align: left;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.preDefineComponentButton svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 25px;
}
.btnWrapper,
.preDefineComponentButton button.btnWrapper {
  background: var(--primary_button_color);
  border: 0;
  color: var(--heading_text_color);
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
}
.preDefineComponentMenu {
  background: var(--accent_color);
  border: 2px solid var(--border_color);
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
}
.preDefineComponent {
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.preDefineComponent:not(:last-child) {
  border-bottom: 1px solid var(--border_color);
}
.preDefineComponent input {
  width: 15px;
  height: 15px;
}
.preDefineComponent label {
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  color: var(--heading_text_color);
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.preDefineComponent label svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.formName {
  margin-bottom: 2rem;
}
.formWrapper {
  border: 2px dashed var(--border_color);
  padding: 25px 30px;
  border-radius: 20px;
}
.formWrapper label,
.formName label {
  color: var(--heading_text_color);
}
.formInputWrapper {
  position: relative;
  margin-bottom: 15px;
}
.formInputWrapper p,
.formInputWrapper ul li,
.formInputWrapper a {
  font-size: 13px;
  font-weight: 400;
}
.formInputWrapper a {
  color: var(--primary_button_color);
  text-decoration: underline;
}
.formInputWrapper ul {
  list-style-type: disc;
  padding-left: 1rem;
}
.formInputWrapperHeading h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--heading_text_color);
}
.formInput {
  margin-bottom: 20px;
}
.formInput h6,
.formInput li {
  font-size: 11px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
  font-weight: 400;
}
.formInput ::-webkit-file-upload-button,
.formInput ::file-selector-button {
  display: none;
}
.formInput input[type="url"],
.formInput input[type="file"] {
  width: 100%;
  background: var(--secondary_color);
  outline: none;
  color: var(--heading_text_color);
  border: 2px solid var(--border_color);
  font-size: 14px;
  padding: 9px 20px;
  border-radius: 20px;
}
.customizedButton {
  position: absolute;
  top: -5px;
  right: 0;
}
/* .predefinedcustomizedButton {
  top: -5px;
} */
.customizedButton button,
.deleteButton {
  background-color: var(--accent_color);
  border: 1px solid var(--accent_color);
  border-radius: 5px;
  color: var(--heading_text_color);
}
.customizedButton button:not(:last-child) {
  margin-right: 5px;
}
.customComponentWrapperInner {
  display: flex;
  flex-wrap: wrap;
}
.customComponentWrapperInner button {
  width: 33.333%;
  /* min-height: 65px; */
  padding: 0 2px;
  margin-bottom: 5px;
  border: 0;
  background: transparent;
}
.customComponentWrapperInner button div {
  background-color: var(--accent_color);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.customComponentWrapperInner button div p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 11px;
}
.customComponentWrapperInner button div .imgWrapper {
  margin-bottom: 8px;
  height: 30px;
}
