:root {
  --darkblue: #21293b;
  --light_grey: #232c3d;
  --dark: #333e56;
  --black: var(--primary_color);
  --gray: #535f79;
  --white: #ffffff;
  --aqua: #22a081;
  --aqua_gradient: linear-gradient(106.6deg, #76e2c6 0%, #22a081 99.84%);
}

/* width */
.collapsedSidebar::-webkit-scrollbar,
.sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.collapsedSidebar::-webkit-scrollbar-track,
.sidebar::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--white);
}

/* Handle */
.collapsedSidebar::-webkit-scrollbar-thumb,
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--dark);
}

.toggleBtnWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
}
.toggleBtn {
  border: 0;
  background: var(--accent_color);
  height: 80px;
  transition: all 0.3s ease;
}
.toggleBtn img {
  transition: all 0.3s ease;
}
.menuBtn {
  border: 0;
  background: var(--accent_color);
  height: 80px;
  font-size: 30px;
  color: var(--primary_text_color);
}
.toHideBtn {
  display: none;
}
.list {
  margin: 25px 15px;
  transition: all 0.3s ease;
}
.helpList {
  margin: 0px 15px;
}
.logOutList {
  margin: 0px 15px 85px 15px;
}
.listItem {
  margin-top: 10px;
  border-radius: 36px;
  transition: all 0.3s ease;
}

.listItem span {
  /* font-size: 20px; */
  margin-bottom: 5px;
}
.listItem span svg {
  width: 20px;
  height: 20px;
}

.rotateSubMenuIcon {
  transform: rotate(180deg);
}
.hideSbmenuContent {
  display: none;
  transition: all 0.3s ease;
}
.showSbmenuContent {
  transition: all 0.3s ease;
  display: block;
  /* color: var(--primary_button_color); */
}
.listItemContentWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.subMenuListItem {
  margin-top: 10px;
  margin-left: 30px;
}
.mainSubItem {
  position: relative;
  margin-top: 10px;
}
.adminPanelCollapsedSubmenu {
  width: 200px;
  background: var(--accent_color);
  position: absolute;
  top: 0;
  left: 100%;
  padding: 10px 15px 20px 15px;
  border-radius: 20px;
  display: none;
  transition: all 0.3s ease;
}
.mainSubItem:hover .adminPanelCollapsedSubmenu {
  display: block;
}
.mainSubItem a {
  border: 2px solid transparent;
}
.mainSubItem a:hover {
  background: var(--tertiary_color);
  border-color: var(--border_color);
}
.mainSubItem a:hover {
  color: var(--primary_text_color);
}

.listItem a {
  border: 2px solid transparent;
}
.listItem:hover a {
  /* background: linear-gradient(106.6deg, var(--primary_button_color_light) 0%, var(--primary_button_color) 99.84%);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4); */
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  color: var(--primary_text_color);
}

.activeItem {
  display: flex;
  align-items: center;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  ) !important;
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  color: var(--heading_text_color) !important;
  gap: 10px;
  height: 40px;
  white-space: nowrap;
  border: 0 !important;
}
.activeItem svg {
  color: var(--heading_text_color) !important;
}
.item {
  display: flex;
  align-items: center;
  color: var(--primary_text_color);
  gap: 10px;
  height: 40px;
  white-space: nowrap;
}

/* 
.listItem svg {
  fill: var(--primary_color);
}

.listItem svg path {
  fill: var(--primary_text_color);
}
.listItem:hover svg {
  fill: #fff;
}
.listItem:hover svg path {
  fill: #fff;
} */

.listItemText {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: horizontal;
}
.logoBtn {
  transition: all 0.3s ease;
}
.nonClickAbleLogoBtn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .menuBtn {
    display: none;
  }
  .nonClickAbleMenuBtn {
    display: block;
  }
  .logoBtn {
    display: none;
  }
  .nonClickAbleLogoBtn {
    display: block;
  }
}
