.mainWrapper {
  border-radius: 20px;
  transition: 0.3s all ease;
  overflow: hidden;
  border: 2px solid var(--border_color);
}

.cardWrapper {
  background-color: var(--tertiary_color);
  box-shadow: 9px 11px 18px rgba(25, 32, 46, 0.5);
  height: 100%;
  border-radius: 20px;
}

.imgWrapper {
  position: relative;
  min-height: 150px;
}

.coverImg {
  position: absolute;
  top: -150px;
  left: 0;
}

.circle {
  filter: blur(25px);
  width: 290px;
  height: 290px;
  border-radius: 50%;
}

.profileImg {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.profileImg img,
.profileImg svg {
  width: 115px;
  height: 115px;
}

.infoWrapper {
  padding: 0 10px;
  margin-top: 45px;
  border-bottom: 1px solid var(--border_color);
  padding-bottom: 45px;
}

.infoWrapper svg {
  font-size: 23px;
  transition: all 0.3s ease;
}

.infoWrapper .name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  transition: all 0.3s ease;
  text-align: center;
  color: var(--heading_text_color);
  white-space: nowrap;
}

.infoWrapper .profession {
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  transition: all 0.3s ease;
  text-align: center;
  padding-top: 10px;
  color: var(--primary_text_color);
}
.btnWrapper {
  margin: 0 24px;
}
.btnWrapper a {
  transition: all 0.3s ease;
}

.createBtn {
  background-color: var(--primary_button_color);
  color: var(--button_text_color);
  width: 100%;
  height: 44.4px;
  border: none;
  border-radius: 50px;
  margin: 20px 0px;
}
.btnWrapper a:hover .createBtn {
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 5.3928px 17.976px rgba(34, 160, 129, 0.4);
  transition: all 0.3s ease;
}

@media only screen and (max-width: 375px) {
  .infoWrapper .name {
    font-size: 18px;
  }
}
