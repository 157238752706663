.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--heading_text_color);
}
.outerWrapper {
  padding: 20px;
}
.title span {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  /* margin-left: 5px; */
}
/* Search Input Styling */
.searchContactInput {
  outline: none;
  padding: 10px 15px 10px 50px;
  border: 1px solid var(--border_color);
  border-radius: 58px;
  color: var(--primary_text_color);
  background: var(--tertiary_color)
    url("../images/contact_page_icons/searchicon.svg") no-repeat 15px center;
  max-width: 262px;
}
.searchContactInput::placeholder {
  color: var(--primary_text_color);
}
/* Different Views and Filter Buttons CSS  */
.veiwsWrapper {
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.activeGrid button {
  background: var(--tertiary_color);
  border: 1px solid var(--border_color);
  border-radius: 25px;
  padding: 5px 14px;
}
.activeGrid .gridIcon {
  color: var(--primary_button_color);
}
.activeGrid button span {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_button_color);
  padding-left: 10px;
}
.grid button {
  background: var(--secondary_Color);
  border: 1px solid var(--tertiary_color);
  border-radius: 25px;
  padding: 5px 14px;
}
.grid .gridIcon {
  color: var(--heading_text_color);
}
.grid button span {
  font-size: 14px;
  line-height: 21px;
  color: var(--heading_text_color);
  padding-left: 10px;
}

.listWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  min-height: 10vh;
  /* padding: 20px; */
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}
.mobileViewWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  display: none;
}
.iconsWrapper button {
  background: transparent;
  border: 0;
}
.mobileActiveGrid svg {
  color: var(--primary_button_color);
}
.movileGrid svg {
  color: var(--secondary_Color);
}
.form {
  margin-bottom: -20px;
}
.mainVeiwsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 10px;
}
.profileWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.profileWrapper img {
  border: 2px solid var(--primary_text_color);
}
.addMemberButton {
  padding: 8px 20px;
  background: var(--primary_button_color);
  border-radius: 10px;
  color: var(--button_text_color);
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid var(--primary_button_color);
}
.count {
  font-size: 12px;
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .mainVeiwsWrapper {
    flex-direction: column;
    align-items: initial;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .title {
    font-weight: 500;
  }
  .title span {
    font-size: 12px;
  }
  .iconsWrapper {
    display: flex;
  }
  /* .mainVeiwsWrapper {
    padding-bottom: 10px;
  } */
  .veiwsWrapper {
    display: none;
  }
  .form {
    /* margin: 10px 20px 0 20px; */
    margin: 10px 0px 0 0px;
    width: 100%;
  }
}
