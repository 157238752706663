.helpHeader {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border_color);
}
.helpImage {
  width: 100%;
  height: 275px;
  background-image: url("../../../images/help/faq_image.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* 2ND SECTION */
.helpbottom {
  margin-top: 50px;
}

.mainWrapper {
  background-color: unset;
  padding: 0 120px;
}

@media only screen and (max-width: 576px) {
  .mainWrapper {
    background-color: unset;
    padding: 0;
  }
}
