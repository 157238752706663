.helpHeader {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border_color);
  padding-bottom: 20px;
}

.helpImage {
  width: 100%;
  height: 275px;
  background-image: url("../../../images/help/tutorial_image.png");
  background-repeat: no-repeat;
  background-size: contain;
}

/* 2ND SECTION */
.helpbottom {
  margin-top: 50px;
}

.videoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoArrowBtn {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--secondary_color);
  color: var(--primary_text_color);
  padding: 4px;
  margin-left: 15px;
  cursor: pointer;
}

.videoHeader span {
  font-weight: 600;
  font-size: 16px;
}

.videoWrapper {
  display: grid;
  grid-auto-flow: column;
  row-gap: 40px;
  column-gap: 40px;
  overflow-x: scroll;
  margin-top: 20px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.videoWrapper::-webkit-scrollbar {
  display: none;
}

.imageWrapper {
  position: relative;
  width: 364px;
  height: 200px;
  margin-bottom: 10px;
}

.imageWrapper img {
  width: 364px;
  height: 200px;
  border-radius: 20px;
  display: block;
}

.imageWrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary_text_color);
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  color: var(--secondary_color);

  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.title {
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  color: var(--primary_text_color);
}

.contentWrapper {
  background: var(--secondary_Color);
  border-radius: 20px;
  padding: 20px;
  margin: 0 120px;
}

.detail {
  font-size: 14px;
  color: var(--primary_text_color);
  margin-top: 10px;
}
.detail h1,
.detail h2,
.detail h3,
.detail h4,
.detail h5,
.detail h6,
.detail strong {
  font-size: 16px;
}
