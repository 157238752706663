.mainWrapper {
  margin-top: 20px;
}
.outerWrapper {
  padding: 20px;
}
.label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}
.inputsWrapper {
  /* display: flex; */
  /* align-items: center; */
  /* gap: 20px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.switchTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.imageWrapper {
  display: flex;
  align-items: end;
  gap: 5px;
  margin-bottom: 30px;
}
.imageWrapper h6 {
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--primary_text_color);
}
.imageWrapper p {
  color: var(--primary_text_color);
  margin-top: 5px;
}
.profileWrapper {
  margin: 30px 15px;
}

.bioDataWrapper {
  padding: 0 25px;
}
.bioDataWrapper h6 {
  font-weight: 500;
  line-height: 24px;
  color: var(--primary_text_color);
}
.bioData {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.title {
  /* font-weight: 500; */
  line-height: 100%;
  color: var(--primary_text_color);
  margin-bottom: 20px;
}
.submitBtnWrapper {
  margin-top: 30px;
}
.submitBtnWrapper button {
  background: var(--primary_button_color);
  border: 0;
  color: var(--button_text_color);
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.subscriptionWrapper {
  padding: 20px;
  margin-inline: 20px;
}

@media only screen and (max-width: 768px) {
  .imageWrapper h6 {
    font-weight: 500;
    font-size: 18px;
  }
  .bioDataWrapper {
    padding: 0 0;
  }
}

@media only screen and (max-width: 576px) {
  .inputsWrapper {
    grid-template-columns: auto;
    gap: 0px;
  }
}
