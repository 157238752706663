.mainWrapper {
  border: 4px solid var(--secondary_color);
  flex: 0 0 220px;
  max-width: 220px;
  transition: 0.3s all ease;
  border-radius: 20px;
  background-color: var(--border_color);
  cursor: pointer;
  position: relative;
}

.mainWrapper:hover {
  border: 4px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px 0px rgba(34, 160, 129, 0.4);
}

/* .mainWrapper:hover .cardWrapper {
  background-color: #fff;
} */

/* .mainWrapper:hover .profileImg img,
.mainWrapper:hover .logoWrapper img {
  border-color: #fff;
} */
.leftSide {
  padding-top: 5px;
}
/* .cardWrapper:hover .leftSide .name,
.cardWrapper:hover .leftSide .profession {
  color: var(--tertiary_color);
}

.cardWrapper:hover .infoWrapper svg {
  fill: var(--tertiary_color);
} */

.imgWrapper {
  position: relative;
}

.coverImg {
  clip-path: polygon(0 0, 100% 0, 100% 55%, 50% 90%, 50% 90%, 0 55%);
}
.threeDots {
  color: var(--primary_text_color);
  visibility: hidden;
  font-size: 20px;
  background-color: #fff;
  padding: 1px;
  border-radius: 50%;
}

.mainWrapper:hover .threeDots {
  visibility: visible;
}

.threeDotsDiv {
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  margin: auto;
  z-index: 3;
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.coverImg img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.profileImg {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary_text_color);
  border-radius: 50%;
}

.profileImg img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--border_color);
  transition: 0.3s all ease;
  filter: drop-shadow(0px 13.6px 20.4px rgba(0, 0, 0, 0.2));
}

.logoWrapper {
  position: absolute;
  top: 110px;
  right: 35px;
}

.logoWrapper img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--border_color);
}

.infoWrapper {
  padding: 0 20px;
  height: 80px;
  margin: 30px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: 0.3s all ease;
}

.infoWrapper svg {
  font-size: 23px;
  transition: all 0.3s ease;
}

.infoWrapper .leftSide .name {
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  color: var(--primary_text_color);
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.infoWrapper .leftSide .profession {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 400;
  transition: all 0.3s ease;
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper img {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}

.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.bodyText {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary_text_color);
  line-height: 14px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.divider {
  width: 100%;
  border: 1px solid var(--border_color);
  opacity: 0.5;
}
.btnWrapper {
  margin-top: 25px;
}
.downloadBtn {
  margin-top: 50px;
  width: 100%;
  border: 0px;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  color: var(--primary_text_color);
  font-size: 14px;
  border-radius: 100px;
  border-top: 1px solid var(--border_color);
  font-weight: 600;
  height: 44px;
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}
