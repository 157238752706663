.btnWrapper {
  margin-top: 50px;
}
.iconWrapper {
  /* width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #e35252; */
}
.iconWrapper svg {
  color: #f15e5e;
  width: 50px;
  height: 50px;
  /* font-size: 46px; */
  /* display: inline-block; */
  margin-top: 13px;
}
.crossBtn {
  color: var(--primary_text_color);
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.confirmBtn {
  background: #e35252;
  border: 0;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.confirmBtn:hover {
  background: #e35252 !important;
  opacity: 0.7;
}
.cancelBtn {
  background: var(--primary_button_color);
  border: 0;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.cancelBtn:hover {
  background: var(--primary_button_color) !important;
  opacity: 0.7;
}
.switchWrapper {
  display: flex;
  align-items: center;
}

.priorityBtn {
  background-color: var(--accent_color);
  color: var(--heading_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;
  align-items: center;
}
.priorityBtn svg {
  width: 18px;
  margin-right: 5px;
}
.priorityBtn span {
  font-size: 13px;
  line-height: 1;
}
.priorityModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}
.priorityModalBody label {
  font-size: 11px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}
.priorityModalBody .customSelect.form-select {
  background: var(--secondary_color);
  border-left: 0;
  border-radius: 60px;
  box-shadow: none;
  font-size: 14px;
  line-height: 100%;
  padding: 9px 10px;
  color: var(--heading_text_color);
  border: 0;
}
.customModal .priorityModalBody .errorText {
  display: none;
}
.saveBtn {
  background-color: var(--primary_button_color);
  color: var(--button_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
}
