.themeWrapper {
  width: fit-content;
  min-width: 42%;
}
.colorBox {
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}
.formBox {
  padding: 0.75rem;
  border: 2px solid var(--border_color);
  background-color: var(--accent_color);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.formBox input[type="color"] {
  width: 100%;
  background: transparent;
  border: 0;
}
.formColorLabel,
.toggleBox label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}
.toggleBox label {
  display: inline-block;
  margin-bottom: 0px;
}
.submitBtnWrapper {
  background: var(--primary_button_color);
  border: 0;
  color: var(--button_text_color);
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 20px;
}
.note {
  font-size: 12px;
  font-weight: 600;
  color: var(--error_color);
  padding: 0 0.5rem;
}
.inActiveColor,
.activeColor {
  width: 8.5%;
  height: 36px;
  margin-right: 1.5%;
  border: 2px solid transparent;
}
.colorButton {
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
}
.activeColor {
  border-color: var(--border_color);
  padding: 2px;
}
.customInput {
  width: 8.5% !important;
  height: 36px;
  position: relative;
  color: var(--heading_text_color);
  border: 2px solid transparent;
}
.customInput input {
  width: 100% !important;
  height: 100%;
  padding: 0;
  cursor: pointer;
  background: transparent;
}
.customInput svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  cursor: pointer;
}
/* .customInput:before {
  content: "";
  position: absolute;
  background: url(../../../../assets/images/edit_card_contact_icons/color_wheel.svg);
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
} */

@media screen and (max-width: 575px) {
  .themeWrapper {
    width: 100%;
  }
}
