.cardWrapper {
  border: 2px dashed var(--border_color);
  width: 212px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 308px;
  height: 100%;
  transition: all 0.3s ease;
}
.cardWrapper a {
  text-align: center;
}
.cardWrapper p {
  padding-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  white-space: nowrap;
}

.cardWrapper:hover {
  background: var(--border_color);
}
