/* Another Approach */
.mainWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 40px;
  overflow-x: auto;
}

.mainWrapper::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.mainWrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--white);
}

/* Handle */
.mainWrapper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--dark);
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.inputsMainWrapper {
  flex: 1;
}
.urliconWrapper {
  min-width: 50px;
  min-height: 50px;
  background: var(--primary_button_color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid var(--primary_text_color);
}
.urliconWrapper svg {
  width: 30px;
}
.inputsSubWrapper {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
}
.inputsSubWrapper input {
  width: 100%;
  /* width: 40%; */
  background: var(--secondary_color);
  border: 2px solid var(--border_color);
  border-radius: 60px;
  outline: none;
  padding: 9px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}
.actionsWrapper img {
  cursor: pointer;
}
.actionsWrapper svg {
  color: var(--primary_text_color);
  cursor: pointer;
}

/* Another Approach */
/* .mainWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 40px;
} */
.inputsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.urlIconWrapper {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary_button_color);
  border: 2px solid var(--primary_text_color);
  border-radius: 50%;
}
.contentWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.customInput {
  min-width: 100%;
  background: var(--secondary_color);
  border: 2px solid var(--border_color);
  border-radius: 60px;
  outline: none;
  padding: 6px 30px;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.datagirdValue {
  font-size: 14px;
  font-weight: 600;
}

/* .actionsWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}
.actionsWrapper img {
  cursor: pointer;
}
.actionsWrapper svg {
  color: var(--primary_text_color);
  cursor: pointer;
} */

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper img {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}

.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.btnWrapper {
  margin-top: 25px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .inputsSubWrapper input {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
  }
  .inputsMainWrapper {
    flex: none;
  }
}
