.navbarSection {
  background: var(--secondary_color);
  height: 60px;
  /* margin-left: 222px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  position: sticky;
  top: 0;
  z-index: 30;
}

.homeText {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--border_color);
}
.homeText:hover {
  color: var(--border_color);
}
.navItemsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-right: 20px;
}
.shareIcon {
  margin-right: 40px;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.navIconBtns {
  background: transparent;
  border: 0;
}
.navIconBtns svg {
  width: 24px;
  height: 24px;
  color: var(--primary_text_color);
  cursor: pointer;
}
.dropdownBtn {
  border-radius: 25px;
  border: 0;
  background: var(--border_color);
}
.dropdownBtn:hover {
  background: var(--border_color);
}
.profileWrapper {
  margin: 30px 20px 20px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border_color);
}
.profileWrapper h6 {
  font-weight: 600;
  font-size: 14px;
  color: var(--heading_text_color);
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profileWrapper p {
  font-size: 8px;
  color: var(--primary_text_color);
  /* margin-top: 5px; */
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.imageBackground {
  border: 1.5px solid var(--primary_text_color);
  filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.2));
}
.btnWrapper {
  text-align: end;
  margin-top: 5px;
}
.btnWrapper button {
  padding: 7px 16px;
  background: var(--primary_button_color);
  border-radius: 50px;
  font-size: 10px;
  /* line-height: 100%; */
  color: var(--heading_text_color);
  border: 0;
  transition: all 0.3s ease;
  min-width: 90px;
}

.btnWrapper button:hover {
  opacity: 0.7;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.iconsWrapper svg {
  width: 20px;
  height: 20px;
}
.iconsWrapper p {
  font-size: 14px;
}
.arrowIcon {
  padding-left: 20px;
  padding-right: 10px;
  color: var(--heading_text_color);
}
.arrowIcon svg {
  width: 15px;
}
.menuIconBtnListItem {
  visibility: hidden;
}

.createTeamBtn {
  border: 0;
  outline: none;
  background: none;
  color: var(--primary_button_color);
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .navbarSection {
    z-index: 20;
  }
  .menuIconBtnListItem {
    visibility: visible;
  }
}
