.inputLabel {
  font-size: 11px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}
.helpHeader {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border_color);
  padding-bottom: 20px;
}
.socialMainWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}
.socialWrapper {
  width: 44px;
  height: 44px;
  background: var(--secondary_color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.socialWrapper:hover {
  opacity: 0.7;
}
.socialWrapper img {
  width: 20px;
  height: 20px;
}
.helpImage {
  width: 100%;
  height: 275px;
  background-image: url("../../../images/help/contact_us.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

/* 2ND SECTION */
.helpbottom {
  margin-top: 50px;
}
.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.cardWrapper {
  width: 250px;
  height: 106px;
  border-radius: 20px;
  background-color: var(--secondary_Color);
  border: 2px solid var(--tertiary_color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s ease;
}

.cardWrapper:hover {
  border: 2px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}

.cardWrapper:hover svg {
  color: var(--primary_button_color);
}

.cardWrapper:hover span {
  color: var(--primary_text_color);
}

.cardWrapper svg {
  color: var(--primary_text_color);
  font-size: 22px;
}

.cardWrapper span {
  color: var(--primary_text_color);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

/* .formWrapper {
  background-color: var(--secondary_Color);
  border-radius: 20px;
  padding: 30px;
} */
.formWrapper {
  background-color: var(--secondary_Color);
  border-radius: 20px;
  padding: 30px;
  margin: 20px 130px;
}
.btnWrapper {
  margin: 0 20px;
}
.sendBtn {
  width: 100%;
  height: 44px;
  color: var(--button_text_color);
  background: var(--primary_button_color);
  border-radius: 68px;
  border: 0px;
  margin-top: 10px;
  transition: all 0.3s ease;
}
.sendBtn:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 768px) {
  .formWrapper {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 576px) {
  .cardsWrapper {
    grid-template-columns: auto;
    justify-content: unset;
  }
  .cardWrapper {
    width: 100%;
  }
}
