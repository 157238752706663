.mainWrapper {
  max-width: 320px;
  margin: auto;
}
.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--primary_text_color);
}
.teamName {
  font-weight: 700;
  line-height: 100%;
  color: var(--primary_text_color);
  text-align: center;
}

.profileCardWrapper {
  background: var(--border_color);
  border-radius: 20px;
  padding: 20px 15px;
  margin-top: 20px;
  min-height: 100px;
}

.companyName {
  font-weight: 600;
  text-align: center;
  color: var(--primary_text_color);
}
.companyDetail {
  color: var(--primary_text_color);
  text-align: center;
  font-size: 12px;
  line-height: 100%;
  margin-top: 10px;
}

.nodeBox {
  background: var(--primary_text_color);
  border-radius: 20px;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  max-height: 150px;
}
.nodeImage {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_button_color);
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  text-transform: capitalize;
  border: 2px solid var(--primary_text_color);
}
.teamMemberImage img {
  width: 50px;
  height: 50px;
  border: 2px solid var(--primary_text_color);
}
.nodeBox .nodeName {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--primary_text_color);
  max-width: 200px;
  text-transform: capitalize;
  text-overflow: ellipsis;
}
.nodeBox .nodeDetail {
  font-weight: 400;
  font-size: 14px;
  color: var(--primary_text_color);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatars {
  display: flex;
  /* margin-left: 20px; */
}
.avatarItem {
  border: 2px solid var(--primary_text_color);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin-left: -40px;
}
.avatarItem:first-child {
  z-index: 5;
  margin-left: 0;
}
.avatarItem:nth-child(2) {
  z-index: 4;
}
.avatarItem:nth-child(3) {
  z-index: 3;
}

.avatarItem img {
  width: 100%;
  height: 100%;
}
.backBtnWrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}
.backBtnWrapper button {
  outline: none;
  border: none;
  color: var(--primary_text_color);
  padding: 8px 14px;
  border-radius: 10px;
  font-size: 12px;
  background: var(--primary_button_color);
}
@media only screen and (max-width: 576px) {
  .title {
    font-weight: 400;
  }
  .imgWrapper img {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 340px) {
  .mainWrapper {
    max-width: 300px;
  }
}
