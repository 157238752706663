.profileWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.profileWrapper img {
  border: 2px solid var(--primary_text_color);
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}
.nodeImage {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_button_color);
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  text-transform: capitalize;
  border: 2px solid var(--primary_text_color);
}
