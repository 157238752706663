.imageWrapper {
  position: relative;
  width: 120px;
  height: 120px;
}
.inputImageWrapper {
  position: relative;
  width: 120px;
  height: 120px;
}
.imageWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.cameraIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileImage {
  border: 2px solid var(--primary_text_color);
}
