@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --primary_color: #1e1e1e;
  --secondary_color: #21293b;
  --accent_color: #19202e;
  --tertiary_color: #232c3d;
  --border_color: #333e56;
  --primary_text_color: #535f79;
  --heading_text_color: #ffffff;
  --button_text_color: #ffffff;
  --primary_button_color: #22a081;
  --primary_button_color_light: #76e2c6;
  --error_color: #e35252;
  --gradient_color_dark: #22a081;
  --gradient_color_light: #76e2c6;
  --gradient_color: linear-gradient(
    106.6deg,
    var(--gradient_color-light) 0%,
    var(--gradient_color-dark) 99.84%
  );
  --social_bg: #10151b;
  --hero_gradient_color: #535f79;
  --scrollbar_thumb: #333e56;
  --scrollbar_track: #ffffff;
  --darkblue: #21293b;
  --light_grey: #232c3d;
  --dark: #333e56;
  --black: #1e1e1e;
  --gray: #535f79;
  --white: #ffffff;
  --aqua: #22a081;
  --aqua_gradient: linear-gradient(106.6deg, #76e2c6 0%, #22a081 99.84%);
}
path[fill="#22A081"] {
  fill: var(--primary_text_color);
}
html,
body {
  padding: 0;
  margin: 0;
  background: var(--primary_color);
  /* overflow-x: hidden; */
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none;
  color: var(--primary_text_color);
}
a:hover {
  cursor: pointer;
}

/* Node Styling */

.css-to7de5 {
  margin-right: 5px !important;
}
/* Image Cropper Styling */
.ReactCrop {
  margin-top: 10px;
  /* width: 100% !important; */
}
.ReactCrop img {
  max-height: 300px !important;
  max-width: 100% !important;
  /* min-width: 100% !important; */
  /* height: 300px !important; */
}

/* Custom CK Editor */
.ck-content {
  border: 2px solid var(--border_color);
  border-radius: 10px;
  padding: 10px;
}

.ck-content.errorInput {
  border: 2px solid #e35252 !important;
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset,
.ck.ck-reset_all {
  background: var(--secondary_color) !important;
  color: var(--primary_text_color) !important;
  border: 0 !important;
}
.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
  box-shadow: none !important;
  border: 0 !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background: var(--secondary_color) !important;
  color: var(--primary_text_color) !important;
  border: 1px solid var(--border_color) !important;
  border-radius: 5px !important;
  outline: none !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  background: var(--secondary_color) !important;
  color: var(--primary_text_color) !important;
  outline: none !important;
  min-height: 200px !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid var(--border_color) !important;
  border-radius: 5px !important;
  min-height: 200px !important;
}
.ck-editor__main {
  background: var(--secondary_color) !important;
  color: var(--primary_text_color) !important;
}
/* Change the chrome auto fill color*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary_color) inset !important;
  -webkit-text-fill-color: var(--heading_text_color) !important;
  /* -webkit-text-fill-color: #535f79 !important; */
}
textarea {
  resize: none;
}

.globalIMageBorder {
  border: 2px solid var(--primary_text_color);
}
.globalIMageFit {
  object-fit: cover;
}
.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  outline: none;
}

.globalBtn {
  border: transparent;
}

input:disabled {
  background-color: var(--accent_color) !important;
  color: var(--primary_text_color) !important;
}

textarea:disabled {
  background-color: var(--accent_color) !important;
  color: var(--primary_text_color) !important;
  border: 0px !important;
}

input:disabled {
  background-color: var(--border_color) !important;
  color: var(--primary_text_color) !important;
  border: 0px !important;
}
/* 
input[type="text"]:focus {
  border-color: var(--primary_button_color) !important;
} */

/* Box Modal CSS */

.customModal .modal-content {
  background: var(--secondary_color) !important;
  min-width: 360px;
  /* padding: 20px; */
  border-radius: 10px;
  border: none;
  /* text-align: center; */
  font-size: 14px;
}

.editQRModal .modal-content {
  box-shadow: 0px 6px 20px 0px rgba(34, 160, 129, 0.4);
}
.customModal .modal-header {
  border-bottom: none;
  position: relative;
  justify-content: space-between;
}
.customModal .modal-body {
  /* padding: 0; */
}
.customModal .modalGradientCircle {
  filter: blur(25px);
  width: 100%;
  height: 150px;
  border-radius: 50%;
  background: radial-gradient(
    52.22% 52.22% at 50% 39.76%,
    var(--primary_button_color) 0%,
    rgba(34, 160, 129, 0) 100%
  );
}
/* Date Picker Styling */
.react-datepicker-wrapper {
  min-width: 100% !important;
}
.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--border_color) !important;
}
.customModal .modalCloseBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 15px;
  cursor: pointer;
}
.customModal h4 {
  text-align: center;
  font-size: 26px;
  /* margin: 30px 0 -10px; */
  margin-top: 10px;
  margin-bottom: -20px;
  color: var(--primary_text_color);
}
.customModal h5 {
  font-weight: 600;
  font-size: 20px;
  color: var(--heading_text_color);
  margin-top: -50px;
  margin-bottom: 10px;
}

.customModal p {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary_text_color);
  line-height: 14px;
}

.customModal .btn-close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.customModal .customModalBtnWrapper {
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--border_color);
}
.customModal .customModalBtnWrapper button {
  width: 100%;
  border: 0px;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  color: var(--heading_text_color);
  font-size: 14px;
  border-radius: 100px;
  font-weight: 600;
  height: 44px;
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  transition: all 0.3s ease;
}
.customModal .customModalBtnWrapper button:hover {
  opacity: 0.7;
}
.customModal .btn-close:focus {
  box-shadow: none !important;
}

.customModal .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary_button_color);
  --bs-btn-border-color: var(--primary_button_color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary_button_color);
  --bs-btn-hover-border-color: var(--primary_button_color);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary_button_color);
  --bs-btn-active-border-color: var(--primary_button_color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary_button_color);
  --bs-btn-disabled-border-color: var(--primary_button_color);
}

/* Global Table CSS Starts*/

/* .listingWrapper .WQNxq,
.listingWrapper .fuLPYh {
  min-height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
} */
.rdt_Table {
  min-height: 100vh !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.rdt_TableHeadRow {
  color: var(--primary_text_color) !important;
  font-size: 14px;
  font-weight: 600;
}

.rdt_Table {
  padding: 10px 0px;
}

.rdt_TableHeadRow {
  border: 0px !important;
}

.rdt_TableRow {
  border: 2px solid var(--border_color) !important;
  border-radius: 20px !important;
  margin-bottom: 20px !important;
  transition: all 0.3s ease !important;
  padding: 10px 0px !important;
  /* flex-wrap: wrap; */
}

.rdt_TableRow:hover {
  border: 2px solid var(--primary_button_color) !important;
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 20px !important;
}

.rdt_TableRow:hover .globalTitle,
.rdt_TableRow:hover .globalUrlPath,
.rdt_TableRow:hover .globalDetail,
.rdt_TableRow:hover .globalNumber,
.rdt_TableRow:hover .detailInput,
.rdt_TableRow:hover .globalVerticalThreeDots {
  color: var(--heading_text_color) !important;
}

/* paginaation dtatable */
.kNfWPo {
  border-top: 0px !important;
}
.rdt_Pagination {
  border: 0 !important;
}
.rdt_Pagination select:focus-visible {
  outline: none !important;
}

.page_Header {
  min-height: 35px;
}

.primary_button {
  padding: 8px 20px;
  background: var(--primary_button_color);
  border-radius: 10px;
  color: var(--button_text_color) !important;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  white-space: nowrap;
}

.primary_button:hover {
  opacity: 0.7;
}
.align_right {
  float: right;
}

.page_Header a:hover {
  color: var(--button_text_color);
}
.pageTitle,
.page_Header .pageTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}
.pageTitle span {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  /* margin-left: 5px; */
}

.p-relative {
  position: relative;
  z-index: 2;
}

.globalName {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  color: var(--primary_text_color);
  transition: all 0.3s ease;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.globalDetail {
  /* margin-top: 5px; */
  font-size: 12px;
  white-space: normal;
  /* text-transform: capitalize; */
  color: var(--primary_text_color);
  transition: all 0.3s ease;
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  /* -webkit-line-clamp: 1; */
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.globalTitle {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary_text_color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  transition: all 0.3s ease;
}
.globalUrlPath {
  font-size: 14px;
  color: var(--primary_text_color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  transition: all 0.3s ease;
}
.globalNumber {
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  color: var(--primary_text_color);
  transition: all 0.3s ease;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailInput {
  line-height: 24px;
  /* text-align: center; */
  text-transform: capitalize;
  color: var(--primary_text_color);
  transition: all 0.3s ease;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.globalVerticalThreeDots {
  color: var(--primary_text_color);
  transition: all 0.3s ease;
}

/* 
td {
  border: solid 1px #000;
  border-style: none solid solid none;
  padding: 10px;
} */
/* 
tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

tr:first-child td {
  border-top-style: solid;
}
tr td:first-child {
  border-left-style: solid;
} */

/* .globalTableRow {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 10px;
  margin: 20px;
} */
/* Global Table CSS Ends */
/* Global Inputs Style*/
/* Input Group Style */
.globalInputWithIcon {
  border: 2px solid var(--border_color);
  border-radius: 60px;
  /* padding: 0 0 0 5px; */
}
.globalInputWithIcon.errorInput {
  border-color: var(--error_color) !important;
}

.globalInputWithIcon.input-group:focus-within {
  border: 2px solid var(--primary_button_color);
}
.globalInputWithIcon .input-group-text {
  background: var(--secondary_color);
  border-right: 0;
  padding: 7px 0px 7px 15px;
  border: 0;
  border-radius: 60px;
}

.globalInputWithIcon .form-control {
  background: var(--secondary_color);
  border-left: 0;
  border-radius: 60px;
  box-shadow: none;
  font-size: 14px;
  line-height: 100%;
  padding: 9px 10px;
  color: var(--heading_text_color);
  border: 0;
  margin-left: 0px;
}
.form-control.inputError {
  color: var(--error_color) !important;
}
.input-group-text img {
  height: 18px;
  width: 18px;
}

.globalInputWithIcon .input-group-text.errorInput {
  border: 2px solid var(--error_color);
  border-right: 0;
}

.globalInputWithIcon .form-control.errorInput {
  border: 2px solid #e35252;
  border-left: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
}

.globalInputWithIcon .form-control::placeholder {
  color: var(--primary_text_color);
  font-size: 14px;
  line-height: 100%;
}

.globalInput {
  width: 100%;
  background: var(--secondary_color);
  border: 2px solid var(--border_color);
  border-radius: 60px;
  padding: 9px 20px;
  outline: none;
  color: var(--primary_text_color);
  font-size: 14px;
  line-height: 100%;
}
.globalInput:focus {
  border: 2px solid var(--primary_button_color);
}

.globalInput::placeholder {
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}

.globalInput.errorInput {
  border: 2px solid #e35252;
  color: #e35252;
}

.globalInput.errorInput:focus {
  border: 2px solid #e35252 !important;
}

.globalSelect {
  background-repeat: no-repeat;
  background-color: transparent;
  width: 100%;
  padding: 9px 20px;
  border: 2px solid var(--primary_text_color);
  font-size: 12px;
  border-radius: 100px;
  box-shadow: none;
  outline: none;
  color: var(--primary_text_color) !important;
}

.globalSelect.errorInput {
  border: 2px solid #e35252;
}

.globalOption {
  color: var(--primary_text_color);
}

.globalTextArea {
  width: 100%;
  height: 215px;
  background: var(--secondary_color);
  outline: none;
  color: var(--heading_text_color);
  border: 2px solid var(--border_color);
  font-size: 14px;
  padding: 9px 20px;
  border-radius: 20px;
}
.globalTextArea:focus {
  border: 2px solid var(--primary_button_color);
}

.globalTextArea.errorInput {
  border: 2px solid var(--error_color) !important;
  color: var(--error_color) !important;
}

.globalTextArea::placeholder {
  color: var(--primary_text_color);
  font-size: 14px;
  line-height: 100%;
}

/* Input Error Message */
.errorText {
  margin: 5px;
  font-size: 12px;
  color: var(--error_color) !important;
}

.uploadBtn {
  background: var(--primary_button_color);
  border-radius: 58px;
  border: 0;
  color: var(--primary_text_color);
  padding: 14px 45px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.uploadBtn:hover {
  opacity: 0.7;
}

.react-tel-input .special-label {
  display: none !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  border-radius: 20px !important;
  /* min-width: 100% !important; */
  width: 100% !important;
  transition: none !important;
}

/* .react-tel-input .form-control:focus {
  border: 0 !important;
} */

/* .emergencyContact .selected-flag .arrow {
  left: 175px !important;
  cursor: pointer !important;
} */
/* .contactNumber .selected-flag .arrow {
  left: 270px !important;
  cursor: pointer !important;
} */
.react-tel-input .country-list {
  background: var(--secondary_color) !important;
  color: var(--primary_text_color) !important;
}

.react-tel-input .country-list .country.highlight {
  background: var(--aqua_gradient) !important;
}

.react-tel-input .country-list li:hover {
  background: var(--aqua_gradient) !important;
}

/* Skip Button CSS */
.skipBtn {
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 38px;
  color: var(--primary_text_color);
}

/* Next Button CSS */
.nextBtn {
  background: transparent;
  border: 0;
  width: 40px;
  height: 40px;
  background: var(--aqua) !important;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.nextBtn:hover {
  opacity: 0.7;
}

.nextBtn img {
  width: 20px;
  height: 20px;
}

/* Add More URL/Function Cards  */
.offcanvas {
  position: absolute !important;
  bottom: 20px;
}

.offcanvas.show {
  bottom: 0 !important;
}

.customOffCanvas {
  width: 380px !important;
  height: 420px !important;
  border-radius: 50px;
}

.offcanvas-backdrop {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Settings Page Tabs CSS */
.customTabs button {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(--primary_text_color) !important;
  border: 0 !important;
  outline: none !important;
  padding: 6px 10px !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.customTabs button:hover {
  border-color: none !important;
  background: var(--primary_button_color) !important;
  color: var(--button_text_color) !important;
}

.customTabs .active {
  background: var(--primary_button_color) !important;
  border: 0 !important;
  border-radius: 5px !important;
  color: var(--button_text_color) !important;
}

.nav-tabs {
  border: 0 !important;
  border-bottom: 1px solid var(--border_color) !important;
  padding-bottom: 15px !important;
}

/* Sign up Page Tabs Styling */
.customSignuUpPageTabs li {
  width: 50% !important;
}
.customSignuUpPageTabs li:first-child button {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.customSignuUpPageTabs li:last-child button {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.customSignuUpPageTabs button {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(--primary_text_color) !important;
  border: 0 !important;
  outline: none !important;
  padding: 14px !important;
  border-radius: 0px !important;
  background: var(--secondary_color) !important;
  width: 100% !important;
  white-space: nowrap !important;
}

/* .customSignuUpPageTabs button:hover {
  border-color: none !important;
  color: #ffffff !important;
} */

.customSignuUpPageTabs .active {
  font-weight: 600 !important;
  background: var(--primary_button_color) !important;
  border: 0 !important;
  /* border-radius: 0px !important; */
  color: var(--heading_text_color) !important;
}

.customSignuUpPageTabs.nav-tabs {
  border: 0 !important;
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

/*Subscription Tabs Styling */
.customSubscriptionTabs li {
  width: 50% !important;
}
.customSubscriptionTabs li:first-child button {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.customSubscriptionTabs li:last-child button {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.customSubscriptionTabs button {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 16px !important;
  color: var(--primary_text_color) !important;
  border: 0 !important;
  outline: none !important;
  padding: 14px !important;
  border-radius: 0px !important;
  background: var(--secondary_color) !important;
  width: 100% !important;
  white-space: nowrap !important;
}

/* .customSignuUpPageTabs button:hover {
  border-color: none !important;
  color: #ffffff !important;
} */

.customSubscriptionTabs .active {
  font-weight: 600 !important;
  background: var(--primary_button_color) !important;
  border: 0 !important;
  /* border-radius: 0px !important; */
  color: var(--button_text_color) !important;
}

.customSubscriptionTabs.nav-tabs {
  border: 0 !important;
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

/* Switches and check inputs*/

.form-check-input {
  cursor: pointer !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--primary_button_color) !important;
  border-color: var(--primary_button_color) !important;
}
.customCheckBox.form-check .form-check-input {
  min-width: 20px !important;
  min-height: 20px !important;
}

.customCheckBox .form-check-label {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary_text_color);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--dark);
}

/* Custom Select in Settings Profile */
.customDateIcon::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23535F79" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  /* cursor: pointer; */
}

.customSelect.form-select {
  background-image: url("../images/settings_page_icons/selectarrow.svg");
  background-repeat: no-repeat;
  background-color: var(--secondary_color);
  color: var(--heading_text_color);
  padding: 9px 20px;
  border: 0;
  /* border: 2px solid var(--border_color); */
  font-size: 14px;
  border-radius: 50px;
  box-shadow: none;
}
.customSelect.form-select.errorInput {
  color: #e35252 !important;
}
.customSelect.form-select > option {
  /* color: #ffffff; */
  /* min-height: 20px; */
}

/* Navbar Dropdown Toggle Customization */
.custom-dropdown-toggle.dropdown-toggle::after {
  display: none;
}

.custom-dropdown-toggle.btn {
  --bs-btn-padding-x: 0.4rem;
  --bs-btn-padding-y: 0.2rem;
}

/* Globally Remove Backdrop of Modal */
/* .modal-backdrop.show {
  opacity: 0;
} */

/*Custom DropDown */

.customActionDropdown.dropdown-toggle {
  border: 0;
}

.manageNodesDropdownWrapper {
  display: flex;
  justify-content: center;
}

.manageNodesDropdownWrapper .dropdown {
  display: inline-block !important;
}
.manageNodesDropdownWrapper .dropdown-toggle {
  white-space: initial !important;
}

.customActionDropdown.btn {
  padding: 0 !important;
}

.filterDropdown.dropdown-toggle::after,
.customActionDropdown.dropdown-toggle::after {
  display: none;
}

.customActionDropdownMenu.dropdown-menu {
  min-width: 220px;
  background: var(--accent_color);
  box-shadow: 9px 11px 18px rgba(25, 32, 46, 0.5);
  border-radius: 20px;
}
.customActionDropdownMenu.dropdown-menu .dropdown-item:focus {
  background-color: transparent;
}

.customActionDropdownMenu.dropdown-menu li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.customActionDropdownMenu.dropdown-menu li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.customActionDropdownMenu.dropdown-menu li {
  padding-left: 10px;
}

.customActionDropdownMenu.dropdown-menu li:hover {
  background: var(--secondary_color);
}

.customActionDropdownMenu.dropdown-menu li:hover a,
.customActionDropdownMenu.dropdown-menu li:hover button {
  color: var(--heading_text_color);
}

.customActionDropdownMenu.dropdown-menu li:hover span {
  color: var(--heading_text_color);
}

.customActionDropdownMenu.dropdown-menu li a,
.customActionDropdownMenu.dropdown-menu li button {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  padding: 10px;
}

.customActionDropdownMenu.dropdown-menu li span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  padding: 10px;
  display: block;
  cursor: pointer;
}

.customActionDropdownMenu.dropdown-menu li a:hover,
.customActionDropdownMenu.dropdown-menu li button:hover {
  background: none;
}

.customActionDropdownMenu.dropdown-menu li span:hover {
  background: none;
}

/* Contact Page Filter Dropdown Customization */
.filterDropdown.dropdown-toggle {
  border: 1px solid var(--tertiary_color) !important;
  background: var(--accent_color) !important;
}

/* Sidebar CSS */
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  width: 228px;
  position: sticky;
  transition: all 0.3s ease;
  /* height: 100%; */
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--accent_color);
}

.collapsedSidebar {
  overflow-y: auto;
  /* overflow-x: hidden; */
  overflow: visible;
  transition: all 0.3s ease;
  width: 74px;
  position: sticky;
  /* min-height: 100%; */
  /* height: 100%; */
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--accent_color);
  z-index: 25;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.backdrop.open {
  display: block;
  z-index: 9;
}

/* Custom CSS of Custom URLS Add&Edit Modal */
.customUrlsAddEditModal .modal-content {
  background: var(--tertiary_color) !important;
  border-radius: 10px;
  top: 40px;
  padding: 0px 40px !important;
}

.customUrlsAddEditModal .modal-header {
  border-bottom: none;
  padding: 20px 0 0 0;
}

.customUrlsAddEditModal .modal-header h6 {
  color: var(--primary_text_color);
}

.customUrlsAddEditModal .modal-body {
  padding: 10px 0;
}

.customUrlsAddEditModal .modal-footer {
  border: none;
  padding: 10px 0 20px 0;
}

.customUrlsAddEditModal .modal-footer button {
  border-radius: 5px;
  font-size: 14px;
  color: var(--primary_text_color);
  padding: 6px 10px;
  width: 80px;
  border: 0;
  transition: all 0.3s ease;
}

.customUrlsAddEditModal .modal-footer button:hover {
  opacity: 0.7;
}

.customUrlsAddEditModal .modal-footer button:nth-child(1) {
  background: #e35252;
}

.customUrlsAddEditModal .modal-footer button:nth-child(2) {
  background: var(--primary_button_color);
}

.customUrlsAddEditModal .btn-close:focus {
  box-shadow: none !important;
}

.customUrlsAddEditModal label {
  display: block;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-top: 10px;
}

.customUrlsInput {
  width: 100%;
  background: var(--secondary_color);
  border: 2px solid var(--border_color);
  border-radius: 50px;
  padding: 7px 20px;
  outline: none;
  margin-top: 10px;
  color: var(--primary_text_color);
  font-size: 14px;
}

.customUrlsInput::placeholder {
  color: var(--primary_text_color);
}

.customUrlsInput.inputError {
  border: 2px solid #e35252;
}

.mainWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  min-height: 10vh;
  /* padding: 20px 0; */
}

/* Global Pagination CSS */
.jaZsat {
  outline: none !important;
  border: 0 !important;
}
.pagination {
  --bs-pagination-color: var(--border_color);
  --bs-pagination-bg: var(--secondary_Color);
  --bs-pagination-border-color: var(--secondary_Color);
  --bs-pagination-border-radius: 5px;
  --bs-pagination-hover-color: #ffffff;
  --bs-pagination-hover-bg: var(--secondary_Color);
  --bs-pagination-hover-border-color: var(--secondary_Color);
  --bs-pagination-focus-color: #ffffff;
  --bs-pagination-focus-bg: var(--secondary_Color);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25remrgba (13, 110, 253, 0.25);
  --bs-pagination-active-color: var(--primary_button_color);
  --bs-pagination-active-bg: var(--tertiary_color);
  --bs-pagination-active-border-color: var(--tertiary_color);
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #ffffff;
}
/* Help Page Global CSS */
.helpPageName {
  font-weight: 700;
  color: var(--primary_text_color);
  margin-bottom: 30px;
}
.helpHeading {
  font-weight: 600;
  /* font-size: 32px; */
  font-size: 26px;
  line-height: 25px;
  color: var(--primary_text_color);
}
.helpSubHeading {
  font-weight: 500;
  color: var(--primary_text_color);
  line-height: 25px;
  margin-top: 20px;
}
.helpDetail {
  color: var(--primary_text_color);
  line-height: 25px;
  margin-top: 10px;
}
.helpImage {
  width: 100%;
  height: 275px;
  object-fit: contain;
}
.helpImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  background: var(--primary_button_color);
  /* opacity: 0.5; */
  /* filter: blur(100px); */
}
.helpBackLinkWrapper {
  margin-bottom: 30px;
}
.helpBackLinkWrapper a {
  background: var(--secondary_color);
  border-radius: 10px;
  padding: 8px 14px;
  color: var(--primary_text_color);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  transition: all 0.3s ease;
}
.helpBackLinkWrapper a:hover {
  opacity: 0.7;
}

/* Color Picker Style Changes */
.sketch-picker {
  background-color: var(--secondary_color) !important;
  box-shadow: none !important;
}

#rc-editable-input-2 + label {
  color: var(--primary_text_color) !important;
}

#rc-editable-input-4 + label {
  color: var(--primary_text_color) !important;
}

#rc-editable-input-6 + label {
  color: var(--primary_text_color) !important;
}

#rc-editable-input-8 + label {
  color: var(--primary_text_color) !important;
}

#rc-editable-input-10 + label {
  color: var(--primary_text_color) !important;
}

.rightAlignedModal {
  margin-right: 0px;
  width: 350px;
}

.formBottom {
  margin-top: 20px;
}
.submitButton {
  padding: 8px 20px;
  background: var(--primary_button_color);
  border-radius: 10px;
  color: var(--heading_text_color) !important;
  transition: 0.3s all ease;
  font-size: 14px;
  border: 0;
}

.submitButton:hover {
  opacity: 0.7;
}

.phoneNumberInput {
  background-color: var(--secondary_color) !important;
  color: var(--heading_text_color) !important;
  border-radius: 60px !important;
  font-size: 14px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  line-height: 100% !important;
  border: 2px solid var(--border_color) !important;
}

.phoneNumberInput.errorInput {
  color: #e35252 !important;
  border-color: #e35252 !important;
}

.phoneNumberInput:focus {
  border: 2px solid var(--primary_button_color) !important;
  box-shadow: none !important;
  transition: none !important;
}

.phoneNumberInput.errorInput:focus {
  border-color: #e35252 !important;
}

.phoneNumberInput::placeholder {
  color: var(--primary_text_color) !important;
  font-size: 14px !important;
  line-height: 100% !important;
}

.phoneNumberInput .form-control {
  border: 2px solid var(--primary_button_color) !important;
}

.phoneNumberInput .form-control:focus {
  border: 2px solid var(--primary_button_color) !important;
}

.view button {
  background: var(--primary_button_color);
  color: var(--primary_text_color);
  border: 0;
  padding: 5px 10px;
  border-radius: 10px;
  display: none;
}

/* Page Not Found & Unauthorized Styling */
.notfoundMainWrapper {
  position: relative;
  height: 100vh;
  background: var(--primary_color);
}

.notfoundMainWrapper .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  text-align: center;
}

.notfound .notfoundBg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

.notfound .notfoundBg > div {
  width: 100%;
  background: var(--tertiary_color);
  border-radius: 90px;
  height: 125px;
}

.notfound .notfoundBg > div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0px 0px var(--secondary_color);
  box-shadow: 5px 5px 0px 0px var(--secondary_color);
}

.notfound .notfoundBg > div:nth-child(2) {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0px 0px var(--secondary_color);
  box-shadow: 5px 5px 0px 0px var(--secondary_color);
  position: relative;
  z-index: 10;
}

.notfound .notfoundBg > div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0px 0px var(--secondary_color);
  box-shadow: 5px 5px 0px 0px var(--secondary_color);
  position: relative;
  z-index: 90;
}

.notfound h1 {
  font-size: 46px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  color: var(--primary_text_color);
}

.notfound h2 {
  font-size: 26px;
  margin: 0;
  font-weight: 700;
  color: var(--primary_text_color);
}

.notfound a,
.notfound button {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--primary_button_color);
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  color: var(--heading_text_color);
  font-weight: 700;
  margin-top: 20px;
  transition: all 0.3s ease;
  border: 0;
}
.notfound a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 768px) {
  .sidebar {
    z-index: 25;
    position: fixed;
    /* margin-left: -250px; */
    transform: translateX(-100%);
  }

  .collapsedSidebar {
    z-index: 25;
    position: fixed;
    /* margin-left: -250px; */

    transform: translateX(-100%);
  }

  .sidebar.show {
    /* margin-left: 0; */

    transform: translateX(0%);
  }

  .collapsedSidebar.show {
    transform: translateX(0%);
  }
  /* Help Page Global CSS */
  .helpPageName {
    font-weight: 600;
    margin-bottom: 20px;
  }
  .helpHeading {
    font-weight: 500;
    font-size: 22px;
  }
  .helpSubHeading {
    font-weight: 400;
    margin-top: 10px;
  }
  .helpDetail {
    font-size: 14px;
  }
  /* Page Not Found & Unauthorized Styling */
  .notfound .notfoundBg {
    width: 280px;
    margin: auto;
  }

  .notfound .notfoundBg > div {
    height: 85px;
  }

  .notfound h1 {
    font-size: 32px;
  }

  .notfound h2 {
    font-size: 14px;
  }
}

.cardView {
  transition: all 0.3s ease;
}

@media only screen and (max-width: 576px) {
  .cardView {
    position: fixed;
    transform: translateX(100%);
    left: 0;
    top: 0;
    z-index: 20;
    height: 100%;
    overflow: auto;
  }
  .view button {
    display: flex;
    margin-bottom: 10px;
  }
  .showCardView {
    transform: translateX(0);
  }
  .customModal .modal-content {
    min-width: initial;
  }
  .pageTitle,
  .page_Header .pageTitle {
    font-weight: 500;
  }
  .pageTitle span {
    font-size: 12px;
  }
  .show {
    /* padding-left: 0 !important; */
  }
  .listingWrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .rdt_TableRow:hover {
    box-shadow: none;
  }
  /* .listingWrapper .WQNxq,
  .listingWrapper .fuLPYh {
    min-height: 100vh;
    padding-left: 0px;
    padding-right: 0px;
  } */
  .rdt_Table {
    min-height: 100vh !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .customActionDropdownMenu.dropdown-menu {
    min-width: 200px;
  }
  /* Page Not Found & Unauthorized Styling */
  .notfound h1 {
    font-size: 26px;
  }

  .notfound h2 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .globalInputWithIcon .form-control {
    padding: 9px 7px;
  }
  /* Page Not Found & Unauthorized Styling */
  .notfound .notfoundBg {
    width: 240px;
  }
  .notfound h2 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 320px) {
  .customActionDropdownMenu.dropdown-menu {
    min-width: 180px;
  }
}
.dobInput.profile {
  height: 42px;
}
.dobInput {
  background: var(--secondary_color);
  border-left: 0;
  border-radius: 60px;
  box-shadow: none;
  font-size: 14px;
  line-height: 100%;
  padding: 9px 10px;
  color: var(--primary_text_color);
  border: 0;
  margin-left: 0px;
  border: 2px solid var(--border_color);
  width: 100%;
  height: 40px;
}

.dobInput:focus-within {
  border: 2px solid var(--primary_button_color);
  outline: none;
}

.dobInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--primary_text_color);
  opacity: 1; /* Firefox */
}
.tab-content .listingWrapper .sc-iuOOrT {
  color: var(--heading_text_color) !important;
  padding: 24px;
}
