.passwordWrapper {
  margin: 30px 15px;
}
.title {
  /* font-weight: 500; */
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 20px;
}
.info {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.inputWrapper {
  margin-top: 10px;
  padding: 0 25px;
}
.input {
  margin-top: 10px;
}

.submitBtnWrapper {
  margin-top: 30px;
}
.submitBtnWrapper button {
  background: var(--primary_button_color);
  border: 0;
  color: var(--button_text_color);
  transition: all 0.3s ease;
  padding: 10px 30px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

@media only screen and (max-width: 768px) {
  .inputWrapper {
    padding: 0 0;
  }
}
