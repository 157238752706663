.mainWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--heading_text_color);
}
.customURLSWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  min-height: 10vh;

  margin-bottom: 50px;
  padding: 40px 0;
  /* padding: 40px 20px; */
}
.addWrapper {
  margin: 0 20px;
}
.addWrapper span {
  cursor: pointer;
}
.addContentWrapper {
  padding: 10px 0px;
  border: 2px dashed var(--border_color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  color: var(--primary_text_color);
  font-size: 36px;
}
.addContentWrapper:hover {
  background: var(--border_color);
}
.addContentWrapper a {
  text-align: center;
}
.addContentWrapper p {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-top: 8px;
}

@media only screen and (max-width: 576px) {
  .mainWrapper {
    flex-direction: column;
    gap: 10px;
    align-items: initial;
  }
}
