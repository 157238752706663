.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--primary_text_color);
}
.tabsWrapper {
  margin-top: 30px;
  background: var(--secondary_color);
  border-radius: 20px;
  width: 100%;
  padding: 30px 15px;
}

.imageWrapper {
  display: flex;
  align-items: end;
  gap: 20px;
  margin-bottom: 30px;
}
.imageWrapper h6 {
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: var(--heading_text_color);
}
.imageWrapper p {
  color: var(--primary_text_color);
  margin-top: 5px;
}
.qrCode {
  position: absolute;
  bottom: -5px;
  right: 0;
  background-color: var(--secondary_color);
  border: 2px solid var(--border_color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saveBtn {
  background-color: var(--primary_button_color);
  color: var(--button_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
}
.alertIcon p {
  line-height: 1.2;
  font-size: 13px;
}
.alertIcon p.modTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
  margin-bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .imageWrapper h6 {
    font-weight: 500;
    font-size: 18px;
  }
}
