.checkoutWrapper {
  height: 100vh;
  position: relative;
  z-index: 1;
  color: var(--heading_text_color);
}
.checkoutWrapper p {
  font-size: 13px;
  color: var(--primary_text_color);
}
.checkoutWrapper h1 {
  font-size: 35px;
  font-weight: 600;
}
.checkoutWrapper h2 {
  font-size: 13px;
  font-weight: 500;
}
.checkoutWrapper p.text-light {
  color: var(--heading_text_color) !important;
}
.checkoutWrapper::before {
  content: "";
  background-color: var(--secondary_color);
  right: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.formLabel {
  font-size: 11px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}
.message {
  color: #e40000;
}
.productImg {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .checkoutWrapper {
    height: auto;
    padding: 2rem 0;
  }
  .checkoutWrapper::before {
    display: none;
  }
  .checkoutWrapper h1 {
    font-size: 25px;
  }
}
