.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--heading_text_color);
  padding: 7px 18px;
  background: var(--secondary_color);
  border: 2px solid var(--primary_text_color);
  border-radius: 20px;
  width: 100%;
  font-size: 14px;
  height: 44px;
}

.placeholder {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.arrow {
  font-size: 26px;
}

.openSelect {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.optionWrapper {
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow: auto;
  height: 145px;
}

.optionWrapper::-webkit-scrollbar {
  display: none;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary_text_color);
  padding: 7px 18px;
  border: 2px solid var(--primary_text_color);
  border-top: 0px;
  height: 44px;
  text-transform: capitalize;
}

.option:hover {
  background-color: var(--border_color);
}

.option:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.selected {
  color: var(--primary_button_color);
  background-color: var(--border_color);
}

.selectedIcon {
  background-color: var(--primary_button_color);
  color: var(--secondary_color);
  border-radius: 50%;
  font-size: 16px;
  margin-right: 5px;
}

.disabled {
  background-color: var(--accent_color);
  border: 0px;
  color: var(--primary_text_color);
}
