.mainDiv {
  margin-bottom: -44px;
  margin-top: -24px;
  margin-right: -4px;
}

.outerWrapper {
  background-color: var(--accent_color);
  padding: 20px 10px;
  position: relative;
  min-height: calc(100vh - 56px);
}

.shareSidebar {
  position: absolute;
  top: 10px;
  left: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  background-color: var(--secondary_color);
  padding: 15px 5px 15px 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.shareSidebar svg {
  color: var(--primary_text_color);
  font-size: 22px;
  cursor: pointer;
}

.shareSidebar img {
  color: var(--primary_text_color);
  width: 16px;
  cursor: pointer;
}

.activeLink {
  background-color: var(--primary_button_color);
  color: var(--primary_text_color) !important;
  border-radius: 7px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--secondary_color);
}

.header span {
  font-size: 14px;
  font-weight: 600;
  color: var(--heading_text_color);
}

.header svg {
  color: var(--primary_text_color);
  font-size: 14px;
  cursor: pointer;
}

.mainWrapper {
  border: 4px solid transparent;
  border-radius: 30px;
  transition: 0.3s all ease;
}

.cardWrapper {
  background-color: var(--button_text_color);
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 25px;
}

/* .mainWrapper:hover {
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border: 4px solid var(--primary_button_color);
} */

.mainWrapper:hover .cardWrapper {
  background-color: var(--button_text_color);
}

/* .mainWrapper:hover .profileImg img,
.mainWrapper:hover .logoWrapper img {
  border-color: #fff;
} */
/* .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35.91px;
  line-height: 54px;
  text-align: center;
  color: var(--tertiary_color);
} */
.profession {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25.65px;
  line-height: 38px;
  text-align: center;
  color: var(--primary_text_color);
}
.company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--primary_text_color);
}
/* .cardWrapper:hover .leftSide .name,
.cardWrapper:hover .leftSide .profession,
.cardWrapper:hover .leftSide .company{
  color: var(--tertiary_color);
} */

.cardWrapper:hover .infoWrapper svg {
  fill: var(--tertiary_color);
}

.imgWrapper {
  position: relative;
}

.coverImg {
  clip-path: polygon(0 0, 100% 0, 100% 55%, 50% 90%, 50% 90%, 0 55%);
}

.coverImg img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.coverImg div {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.profileImg {
  position: absolute;
  top: 115px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--border_color);
  border-radius: 50%;
}
.profileImg.rectangular {
  top: 122px;
}
.profileImg img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  /* border: 2px solid var(--primary_text_color); */
  transition: 0.3s all ease;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.profileImg div {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--heading_text_color);
  transition: 0.5s all ease;
}

.logoWrapper {
  position: absolute;
  top: 152px;
  right: 31%;
}
.logoWrapper img {
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  object-fit: contain;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--primary_text_color);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.logoWrapper div {
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  object-fit: contain;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--primary_text_color);
}
.logoWrapper.circle div,
.logoWrapper.circle img {
  border-radius: 50%;
}
.logoWrapper.square div,
.logoWrapper.square img {
  border-radius: 2px;
}
.logoWrapper.rectangular div,
.logoWrapper.rectangular img {
  border-radius: 2px;
  width: 50px;
  height: 25px;
  transition: 0.5s all ease;
}

.infoWrapper {
  padding: 0 20px;
  margin: 25px 0px 3px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  transition: 0.3s all ease;
}

.infoWrapper svg {
  font-size: 23px;
  transition: all 0.3s ease;
}

.cardType {
  text-align: center;
  width: 78px;
  height: 23px;
  border-radius: 60px;
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
}

.cardType span {
  font-weight: 400;
  font-size: 10px;
  color: var(--heading_text_color);
  text-transform: capitalize;
}

.infoWrapper .leftSide .name {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  transition: all 0.3s ease;
  text-align: center;
  word-wrap: break-word;
  /* text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap; */
}

.infoWrapper .leftSide .profession {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  transition: all 0.3s ease;
  color: var(--primary_text_color);
}
.descriptionWrapper {
  /* padding: 0px 20px 40px 20px; */
  padding: 0 20px;
}
.descriptionWrapper h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--border_color);
}
.descriptionWrapper hr {
  color: #bdbdbd;
  margin: 22px 0 22px;
}
.descriptionWrapper span {
  color: var(--primary_text_color);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  text-align: center;
  display: block;
}
.descriptionWrapper span button {
  background: none;
  border: 0;
  color: var(--primary_button_color);
}
.socialMediaWrapper {
  margin: 0px 20px 0px 20px;
  background-color: #eee;
  padding: 26px;
  border-radius: 20px;
}
.socialMediaWrapper h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary_text_color);
}
.socialMediaWrapper hr {
  color: #bdbdbd;
  margin: 0;
}
.moreInfoIcon {
  border-radius: 42px;
  min-width: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreInfoIcon svg {
  font-size: 40px;
  width: 20px;
  height: 20px;
  color: var(--heading_text_color);
  /* padding: 10px; */
}

.socialMediaIcon {
  border-radius: 42px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 22px;
}

.socialMediaIcon:hover {
  color: #fff;
}

.socialMediaIcon svg {
  width: 22px;
}

.socialMediaIcon svg path {
  fill: #fff;
}

.socialMediaIconWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 15px;
  margin-top: 15px;
}
.contactInfo {
  margin: 0px 20px 20px 20px;
  background-color: #eee;
  padding: 26px;
  border-radius: 20px;
}
.contactInfo h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary_text_color);
}
.contactInfo hr {
  color: #bdbdbd;
  margin: 0;
}
.infoContnt {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.infoContnt p {
  line-height: 27px;
  font-size: 12px;
  font-weight: 600;
  color: var(--border_color);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
.infoContnt a {
  font-size: 11px;
  font-weight: 600;
  color: #222;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  word-break: break-all;
}
.infoContnt .subHeading {
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
}
.imageWrapper {
  background: var(--primary_button_color);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageWrapper img {
  width: 20px;
  height: 20px;
}
.imageWrapper div {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.textSkeleton {
  background-color: #e8e8e8;
  border-radius: 40px;
}
.iconSkeleton {
  background-color: #e8e8e8;
  width: 36px;
  height: 36px;
  border-radius: 36px;
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 15px;
  cursor: pointer;
}

.socialLinkWrapper {
  background-color: #1d2435;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
}

.socialModalLinkIcon {
  border-radius: 52px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.socialModalLinkIcon svg {
  font-size: 45px;
  color: var(--primary_text_color);
  padding: 10px;
}

.mediaType {
  font-weight: 400;
  font-size: 11px;
  color: var(--primary_text_color);
  text-transform: capitalize;
  margin-top: 3px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}
.savePhonebookBtnWrapper {
  margin-bottom: 20px;
}
.savePhonebookBtnWrapper button {
  background: var(--primary_button_color);
  color: var(--button_text_color);
  border: 0;
  border-radius: 50px;
  height: 40px;
  width: 160px;
  font-weight: 600;
  font-size: 14px;
}
.savePhonebookBtnWrapper button span {
  margin-right: 5px;
}
.savePhonebookBtnWrapper button span img {
  width: 20px;
  height: 20px;
}

.cardFooter {
  text-align: center;
  margin-top: 20px;
  background: var(--secondary_color);
  padding: 20px;
  border-radius: 20px;
}
.cardFooter p {
  color: var(--primary_text_color);
  /* color: var(--primary_text_color); */
  margin-top: 10px;
}

@media only screen and (max-width: 576px) {
  .mainDiv {
    margin-bottom: 0;
    margin-top: -7px;
    margin-right: 0;
  }

  .coverImg img {
    height: 240px;
  }

  .profileImg {
    top: 125px;
  }

  .profileImg img {
    width: 110px;
    height: 110px;
  }

  .logoWrapper {
    top: 128px;
    right: 40px;
  }

  .logoWrapper img {
    width: 60px;
    height: 60px;
  }

  .infoContnt a {
    font-size: 14px;
  }
}
