.outerWrapper {
  display: flex;
  margin-bottom: 20px;
  column-gap: 10px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}

.mainWrapper {
  background-color: var(--secondary_color);
  padding: 20px;
  border-radius: 20px;
}

.mainWrapper hr {
  color: var(--border_color);
}

.colorWrapper {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 20px 10px;
  display: flex;
  column-gap: 10px;
  width: fit-content;
  margin-bottom: 25px;
}

.colorWrapper div {
  cursor: pointer;
  width: 31px;
  height: 31px;
  border-radius: 31px;
}

.sectionHeader {
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sectionIconHeader {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--border_color);
}

.sectionHeader span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
}
.shapeWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.shapeBox {
  flex: 0 0 auto;
  width: 25%;
  cursor: pointer;
}
.shapeWrapper .shapeBox:first-child {
  margin-left: 3rem;
}
.contentViewBox {
  border-radius: 5rem;
  border: 2px solid var(--border_color);
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.viewImage {
  width: 30px;
  margin-right: 0.75rem;
}
.contentViewBox p {
  font-size: 14px;
}
.backgroundWrapper {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  align-items: center;
  justify-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  /* max-width: 387px; */
  max-width: 48%;
  margin-bottom: 30px;
}

.backgroundWrapper img {
  cursor: pointer;
  width: 54px;
  height: 54px;
  border-radius: 20px;
  border: 1px solid var(--border_color);
  object-fit: cover;
}

.emptyBackgroundImage {
  width: 54px;
  height: 54px;
  border: 1px dashed var(--border_color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptyBackgroundImageRow {
  display: flex;
  grid-column-gap: 20px;
}

.emptyBackgroundImage span {
  font-size: 20px;
  font-weight: 600;
  color: var(--border_color);
}

.emptyBackgroundImage:hover {
  border-color: var(--primary_button_color);
  cursor: pointer;
}

.emptyBackgroundImage:hover span {
  color: var(--primary_button_color);
}
.textareaInput {
  width: 100%;
  background: var(--secondary_color);
  outline: none;
  color: var(--primary_text_color);
  border: 2px solid var(--primary_text_color);
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 20px;
  height: 140px;
}

.textareaInput::placeholder {
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}

.socialMediaWrapper {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  max-width: 387px;
  margin-bottom: 30px;
}

.addedSocialLinks {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_button_color);
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
  text-transform: capitalize;
}
.addedSocialLinks svg {
  width: 22px;
  height: 22px;
}
.addedSocialLinks svg path {
  fill: #fff;
}

.addMoreSocialLinks {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--primary_button_color);
  color: var(--primary_button_color);
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.uploadBtn {
  background-color: var(--primary_button_color);
  padding: 8px, 14px;
  font-size: 12px;
  font-weight: 400;
  color: var(--button_text_color);
  border: 0px;
  border-radius: 10px;
  width: 101px;
  height: 34px;
  display: block;
}

.removeImgBtn {
  background-color: var(--accent_color);
  padding: 8px, 14px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary_text_color);
  border: 0px;
  border-radius: 10px;
  width: 163px;
  height: 34px;
  margin-top: 10px;
}

.imageHeaderLabel {
  font-size: 12px;
  font-weight: 500;
}

.submitBtnWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitBtn {
  color: var(--button_text_color);
  background-color: var(--primary_button_color);
  border: 0px;
  width: 150px;
  height: 40px;
  font-weight: 300;
  border-radius: 10px;
}

.selectedOption {
  border: 2px solid var(--primary_button_color) !important;
}

.selectedColorOption {
  border: 2px solid var(--primary_text_color) !important;
}

.mediaModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.mediaModalHeader svg {
  color: var(--primary_text_color);
  font-size: 15px;
  cursor: pointer;
}

.mediaModalBody p {
  color: var(--primary_text_color);
  text-align: start;
}

.mediaModalBody select {
  margin-top: 20px;
  width: 100%;
}

.mediaModalFooter button {
  background-color: var(--primary_button_color);
  border-radius: 100px;
  padding: 13px 54px;
  color: var(--heading_text_color);
  border: 0px;
  width: 100%;
}

.socialMediaModalLink {
  border: 2px solid var(--border_color);
  background-color: var(--secondary_color);
  border-radius: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  column-gap: 10px;
  height: 47.13px;
}

.socialMediaModalLinkIcon {
  font-weight: 800;
  text-transform: capitalize;
  font-size: 24px;
  color: var(--primary_button_color);
  width: 22px;
  text-align: center;
}

.socialMediaModalLinkIcon svg {
  width: 22px;
}

.socialMediaModalLinkIcon svg path {
  fill: var(--primary_button_color);
}

.socialMediaModalLink input {
  font-size: 12px;
  font-weight: 500;
  color: var(--primary_text_color);
  background-color: var(--secondary_color);
  border: 0;
  width: 100%;
  padding: 2px;
}

.socialMediaModalLink input:focus {
  border: 1px solid var(--border_color);
  outline: none;
  border-radius: 5px;
}

.socialLinkDeleteIcon {
  color: #e35252;
  font-size: 17px;
  cursor: pointer;
}

.imageUpload {
  border: 2px dashed var(--primary_text_color);
  padding: 45px 25px;
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.imageUpload svg {
  color: var(--primary_text_color);
  font-size: 36px;
}

.imageUpload span:first-of-type {
  margin-top: 15px;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
}

.imageUpload span:first-of-type span {
  color: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.imageUpload span:first-of-type span:hover {
  text-decoration: underline;
}

.imageUpload span:last-of-type {
  margin-top: 10px;
  color: var(--primary_text_color);
  font-weight: 400;
  font-size: 11px;
}

.select {
  color: var(--primary_text_color);
  padding: 7px 12px;
  background: var(--secondary_color);
  border: 2px solid var(--primary_text_color);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  font-size: 14px;
}

.select option {
  font-weight: 500;

  line-height: 24px;

  color: var(--primary_text_color);
}

.select:focus,
.select option:focus {
  outline: none;
}

.customBackgroundWrapper {
  position: relative;
}

.customBackgroundWrapper img {
  object-fit: cover;
}

.bgImgClearBtnWrapper {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgImgClearBtn {
  cursor: pointer;
}

.backgroundImageWrapper {
  position: relative;
}
.bgImgRemoveBtnWrapper {
  width: 18px;
  height: 18px;
  border: 1px solid var(--border_color);
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -3px;
  right: -3px;
  font-size: 10px;
}
/* .removeImgBtn:hover {
  background-color: var(--primary_button_color);
  padding: 8px, 14px, 8px, 14px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary_text_color);
} */

.divider {
  opacity: 1;
  background-color: var(--border_color);
}

.phoneInputMainWrapper {
  width: 367px;
}
.addPhonutBtnWrapper {
  margin-bottom: 25px;
  text-align: end;
  margin-top: 20px;
}
.addPhonutBtnWrapper button {
  background: var(--primary_button_color);
  outline: none;
  /* width: 160px; */
  padding: 0 20px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: var(--button_text_color);
  border: 0;
  border-radius: 25px;
}
.removePhonutBtnWrapper button svg {
  width: 25px;
  height: 25px;
  color: #e35252;
}
.formInputWrapper {
  margin-top: 25px;
}
.removePhonutBtnWrapper button {
  /* background: #e35252; */
  background: transparent;
  outline: none;
  border: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: var(--primary_text_color);
}
.contactPhoneInputOuterWrapper {
  border: 2px solid var(--primary_text_color);
  border-radius: 25px;
  margin-top: 10px;
  padding: 10px;
}
.phoneInputOuterWrapper {
  border: 2px solid var(--primary_text_color);
  border-radius: 25px;
  margin-top: 10px;
  padding: 10px;
  width: 387px;
}
@media only screen and (max-width: 576px) {
  .colorWrapper {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .socialMediaWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .phoneInputOuterWrapper,
  .formInputWrapper,
  .phoneInputMainWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .mainWrapper {
    padding: 15px 10px;
  }
}
