.mainWrapper {
  margin: auto;
}
.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--heading_text_color);
}
.cardWrapper {
  margin-top: 20px;
  /* background: var(--secondary_color); */
  border-radius: 20px;
  /* width: 100%; */
  /* padding: 30px; */
}
.detailWrapper h6 {
  color: var(--tertiary_color);
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
}
.detailWrapper p {
  color: var(--tertiary_color);
  text-align: center;
  margin-top: 10px;
}
.detailWrapper .description {
  color: var(--primary_text_color);
  font-size: 14px;
}
.detailWrapper .description button {
  background: transparent;
  border: 0;
  color: var(--primary_button_color);
}

.profileCardWrapper {
  background: var(--heading_text_color);
  border-radius: 20px;
  padding: 40px 15px;
}
.imgWrapper {
  text-align: center;
}
.imgWrapper img {
  box-shadow: 2px 4px 15px 2px rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 100px;
}

formWrapper {
  margin-top: 50px;
}
.submitBtns {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submitBtns button:hover {
  opacity: 0.7;
}
.cancelBtn {
  background: var(--secondary_Color);
  border-radius: 50px;
  color: var(--border_color);
  border: 0;
  width: 90px;
  padding: 8px 0px;
  color: var(--border_color);
}
.confirmBtn {
  border: 0;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  color: var(--primary_text_color);
  width: 90px;
  padding: 8px 0px;
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 50px;
}

.textWrapper {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
}
.contentWrapper {
  display: flex;
  justify-content: center;
  /* align-items: self-start; */
  align-items: flex-start;
  gap: 20px;
}
.contentWrapper h6 {
  font-weight: 600;
  line-height: 24px;
  color: var(--primary_text_color);
  /* margin-bottom: 10px; */
}
.contentWrapper p {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}

.formLabel {
  font-size: 11px;
  color: var(--primary_text_color);
}
.label {
  font-size: 10px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.savePhonebookBtnWrapper button {
  color: var(--heading_text_color);
  border: 0;
  border-radius: 50px;
  height: 40px;
  width: 160px;
  font-weight: 600;
  font-size: 14px;
  background: var(--primary_button_color);
  margin-top: 20px;
}
.savePhonebookBtnWrapper a button {
  background: #e35252;
}
.savePhonebookBtnWrapper button span {
  margin-right: 5px;
}
.savePhonebookBtnWrapper button span img {
  width: 20px;
  height: 20px;
}
.cardFooter {
  text-align: center;
  margin-top: 20px;
  background: var(--secondary_color);
  padding: 20px;
  border-radius: 20px;
}
.cardFooter p {
  color: var(--primary_text_color);
  /* color: var(--primary_text_color); */
  margin-top: 10px;
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper img {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}

.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.btnWrapper {
  margin-top: 25px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}

.btn {
  background: transparent !important;
  border: 2px solid var(--primary_button_color) !important;
  color: var(--primary_button_color) !important;
  width: 130px !important;
}

@media only screen and (max-width: 576px) {
  .title {
    font-weight: 400;
  }
  .imgWrapper img {
    width: 110px;
    height: 110px;
  }
}
