.emergencyContactWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  box-shadow: 9px 11px 18px rgba(25, 32, 46, 0.5);
  border-radius: 20px;
  padding: 18px 16px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.emergencyContactWrapper:hover {
  border: 2px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}
.contentWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contentWrapper img {
  border: 2px solid var(--primary_text_color);
}
.numberWrapper svg {
  color: var(--primary_text_color);
  width: 12px;
  height: 12px;
}
.numberWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.name {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary_text_color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
.number {
  font-size: 10px;
  color: var(--primary_text_color);
  white-space: nowrap;
}
.threeDotsIcon {
  color: var(--primary_text_color);
}

.emergencyContactWrapper:hover .name,
.emergencyContactWrapper:hover .threeDotsIcon,
.emergencyContactWrapper:hover .numberWrapper svg,
.emergencyContactWrapper:hover .number {
  color: var(--primary_text_color);
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper img {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}

.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.btnWrapper {
  margin-top: 25px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .name {
    width: 500px;
  }
}
@media only screen and (max-width: 576px) {
  .name {
    width: 150px;
  }
}
