.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.boxWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 40px 20px;
  overflow: auto;
  min-height: 100vh;
}

/* width */
.boxWrapper::-webkit-scrollbar {
  height: 5px;
}

/* Track */

.boxWrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--primary_text_color);
}

/* Handle */

.boxWrapper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--border_color);
}

.nodeBox {
  padding: 10px;
  border-radius: 3px;
  background: var(--primary_button_color);
  /* border: 2px solid var(--primary_text_color); */
  min-height: 40px;
  word-break: normal;
  cursor: pointer;
  color: var(--primary_text_color);
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper img {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}

.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.btnWrapper {
  margin-top: 25px;
}

.memberNodeBox {
  background: var(--accent_color);
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  max-height: 150px;
  width: 240px;
}
.nodeImage {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_button_color);
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  text-transform: capitalize;
  border: 2px solid var(--primary_text_color);
}
.teamMemberImage img {
  width: 50px;
  height: 50px;
  border: 2px solid var(--primary_text_color);
}
.memberNodeBox .nodeName {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--heading_text_color);
  max-width: 140px;
  text-transform: capitalize;
  text-overflow: ellipsis;
}
.memberNodeBox .nodeDetail {
  font-weight: 400;
  font-size: 13px;
  color: var(--primary_text_color);
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.threedotsWrapper svg {
  color: var(--heading_text_color);
  visibility: hidden;
}
.memberNodeBox:hover .threedotsWrapper svg {
  visibility: visible;
}
