.aboutUsHeader {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border_color);
}

.aboutUsContentWrapper {
  margin-top: 40px;
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 32px 20px;
}
.aboutContentWrapper h6 {
  font-weight: 600;
  line-height: 25px;
  color: var(--primary_text_color);
}
.aboutContentWrapper p:nth-child(2) {
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.aboutContentWrapper p:nth-child(3) {
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.box {
  background: var(--border_color);
  border-radius: 20px;
  height: 268px;
}
/* Goal Section CSS */
.goalSection {
  margin-top: 40px;
}
.goalSection h5 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  color: var(--primary_text_color);
}
.goalContentWrapper {
  margin-top: 40px;
  padding: 0px 100px 0px 100px;
}
.headlineContentWrapper h6 {
  font-weight: 600;
  line-height: 25px;
  color: var(--primary_text_color);
}
.headlineContentWrapper p:nth-child(2) {
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.headlineContentWrapper p:nth-child(3) {
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.goalBox {
  margin-top: 10px;
  background: var(--border_color);
  border-radius: 20px;
  height: 220px;
}
.textWrapper {
  margin-top: 20px;
}
.textWrapper p:nth-child(1) {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.textWrapper p:nth-child(2) {
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.lastBox {
  margin-top: 40px;
  background: var(--secondary_color);
  border-radius: 20px;
  height: 170px;
  margin-bottom: 80px;
}
.logoWrapper {
  margin-top: 50px;
  text-align: center;
}
.logoWrapper p {
  font-size: 14px;
}
.logoWrapper p:nth-child(2) {
  max-width: 345px;
  margin: 40px auto 10px auto;
}

.contentWrapper {
  background: var(--secondary_Color);
  border-radius: 20px;
  padding: 20px;
  margin: 0 120px;
}
.detail {
  font-size: 14px;
  color: var(--primary_text_color);
  margin-top: 10px;
}
.detail h1,
.detail h2,
.detail h3,
.detail h4,
.detail h5,
.detail h6,
.detail strong {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .contentWrapper {
    margin: 0;
  }
}
@media only screen and (max-width: 576px) {
  /* .contentWrapper h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .contentWrapper p:nth-child(2) {
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }
  .contentWrapper p:nth-child(3) {
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }

  .aboutContentWrapper h6 {
    font-weight: 500;
    line-height: 24px;
  }
  .aboutContentWrapper p:nth-child(2) {
    line-height: 20px;
    font-size: 12px;
  }
  .aboutContentWrapper p:nth-child(3) {
    line-height: 20px;
    font-size: 12px;
  }

  .goalSection h5 {
    font-weight: 500;
    font-size: 20px;
  }
  .headlineContentWrapper h6 {
    font-weight: 400;
  }
  .headlineContentWrapper p:nth-child(2) {
    font-size: 12px;
    line-height: 20px;
  }
  .headlineContentWrapper p:nth-child(3) {
    font-size: 12px;
    line-height: 20px;
  }
  .textWrapper p:nth-child(1) {
    font-size: 12px;
    line-height: 20px;
  }
  .textWrapper p:nth-child(2) {
    font-size: 12px;
    line-height: 20px;
  } */
}
