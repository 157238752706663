.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 2rem;
  color: var(--primary_text_color);
}

.mainWrapper {
  background-color: var(--secondary_color);
  padding: 20px;
  border-radius: 20px;
}
.table tr {
  border-color: var(--border_color);
}
.table th {
  background-color: var(--accent_color);
  color: var(--heading_text_color);
  font-size: 16px;
  padding: 1rem 1.5rem;
  border-color: var(--border_color);
}
.table td {
  background-color: var(--tertiary_color);
  color: var(--heading_text_color);
  font-size: 13px;
  padding: 1rem 1.5rem;
  border-color: var(--border_color);
  vertical-align: middle;
}
