.heroSectionWrapper {
  background: linear-gradient(
    0deg,
    rgba(83, 95, 121, 0) -4.29%,
    var(--hero_gradient_color) 102.78%
  );
  border: 2px solid var(--border_color);
  border-radius: 40px;
  padding: 40px 40px 0px 40px;
  position: relative;
  margin: 50px 0px;
}
.heroSectionWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/src/assets/images/home_page_images/herobg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  z-index: 1;
  opacity: 0.5;
}

.removeHeroSectionWrapper {
  display: none;
}
.heroSectionRemoveBtn {
  position: absolute;
  top: -20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
}
.heroSectionWrapper h5 {
  font-weight: 700;
  font-size: 32px;
  color: var(--heading_text_color);
  margin-bottom: 15px;
}

.heroSectionWrapper p {
  font-size: 12px;
  line-height: 20px;
  max-width: 430px;
  color: var(--heading_text_color);
  margin-bottom: 20px;
}
.imagesWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.imagesWrapper img {
  width: 100%;
}
.downloadBtnWrapper {
  margin-bottom: 20px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.downloadBtn {
  font-weight: 500;
  transition: all 0.3s ease;
  outline: none;
  border: 0;
  height: 44px;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 58px;
  width: 160px;
  color: var(--heading_text_color);
  white-space: nowrap;
}
.cancelBtn {
  font-weight: 500;
  transition: all 0.3s ease;
  outline: none;
  height: 44px;
  border: 0;
  color: var(--primary_button_color);
  border: 2px solid var(--primary_button_color);
  border-radius: 58px;
  width: 160px;
  background: transparent;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.heroImgWrapper {
  margin-top: -100px;
}
.heroImgWrapper svg {
  width: 100%;
  height: 100%;
}
.title {
  font-weight: 700;
  font-size: 32px;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
@media only screen and (max-width: 768px) {
  .heroSectionWrapper h5 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .heroSectionWrapper p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .downloadBtnWrapper {
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .heroSectionWrapper h5 {
    font-weight: 500;
    font-size: 20px;
  }
  .imagesWrapper {
    justify-content: center;
  }

  .heroSectionWrapper p {
    font-size: 12px;
  }
  .downloadBtn,
  .cancelBtn {
    width: 130px;
    height: 35px;
    font-size: 14px;
  }
  .downloadBtnWrapper {
    justify-content: center;
  }
}
@media only screen and (max-width: 375px) {
  .heroSectionWrapper {
    padding: 10px 10px 0px 10px;
  }

  .heroImgWrapper {
    margin-top: -60px;
  }
}
@media only screen and (max-width: 340px) {
  .downloadBtn,
  .cancelBtn {
    width: 120px;
    height: 35px;
  }
}

@media only screen and (max-width: 320px) {
  .heroSectionWrapper {
    padding: 10px 10px 0px 10px;
  }

  .heroImgWrapper {
    margin-top: -60px;
  }
}
