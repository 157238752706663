.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}

.outerWrapper {
  display: flex;
  margin-bottom: 20px;
  column-gap: 10px;
}

.mainWrapper {
  min-height: calc(100vh - 133px);
  background-color: var(--secondary_color);
  border-radius: 20px;
  position: relative;
}

.cardFormWrapper {
  height: 100%;
  padding: 20px;
}

.cardHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardBodyWrapper {
  max-width: 300px;
  margin: 0 auto;
}
.cardBodyWrapperBig {
  max-width: 80%;
  margin: 0 auto;
}
.cardBodyWrapperNew {
  max-width: 85%;
  margin: 100px auto 0;
}
.directionButtons {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
}

.prevBtn {
  position: absolute;
  left: 0;
  top: 50%;
  color: var(--primary_text_color);
  width: 44px;
  height: 160px;
  font-size: 25px;
  border: none;
  transform: translate(0%, -50%);
  overflow: hidden;
  visibility: hidden;
}
.prevBtn:disabled {
  display: none;
}
.prevBtn svg {
  visibility: visible;
}

.prevBtn:before {
  content: "";
  display: block;
  width: 100%;
  height: 60%;
  background-color: var(--secondary_color);
  /* transform: skewY(21deg); */
  transition: 0.5s;
  border-top-right-radius: 15px;
  position: absolute;
  top: 8px;
  left: 0;
  z-index: -1;
  visibility: visible;
}

.prevBtn:after {
  content: "";
  display: block;
  width: 100%;
  height: 60%;
  background-color: var(--secondary_color);
  /* transform: skewY(-21deg); */
  transition: 0.5s;
  border-bottom-right-radius: 15px;
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: -1;
  visibility: visible;
}

.prevBtn:hover:before {
  background-color: var(--primary_button_color);
}

.prevBtn:hover:after {
  background-color: var(--primary_button_color);
}

.prevBtn:hover {
  color: var(--button_text_color);
}

.prevBtn:disabled:hover {
  color: var(--primary_text_color);
}

.prevBtn:disabled:hover:before {
  background-color: var(--secondary_color);
}

.prevBtn:disabled:hover:after {
  background-color: var(--secondary_color);
}

.nextBtn {
  position: absolute;
  right: 0;
  top: 50%;
  color: var(--primary_text_color);
  width: 44px;
  height: 160px;
  font-size: 25px;
  border: none;
  transform: translate(0%, -50%);
  overflow: hidden;
  visibility: hidden;
}
.nextBtn:disabled {
  display: none;
}
.nextBtn svg {
  visibility: visible;
}

.nextBtn:before {
  content: "";
  display: block;
  width: 100%;
  height: 60%;
  background-color: var(--secondary_color);
  /* transform: skewY(-21deg); */
  transition: 0.5s;
  border-top-left-radius: 15px;
  position: absolute;
  top: 8px;
  right: 0;
  z-index: -1;
  visibility: visible;
  overflow: hidden;
}

.nextBtn:after {
  content: "";
  display: block;
  width: 100%;
  height: 60%;
  background-color: var(--secondary_color);
  /* transform: skewY(21deg); */
  transition: 0.5s;
  border-bottom-left-radius: 15px;
  position: absolute;
  bottom: 8px;
  right: 0;
  z-index: -1;
  visibility: visible;
  overflow: hidden;
}

.nextBtn:hover {
  color: var(--button_text_color);
}

.nextBtn:hover:before {
  background-color: var(--primary_button_color);
}

.nextBtn:hover:after {
  background-color: var(--primary_button_color);
}

.nextBtn:disabled:hover {
  color: var(--primary_text_color);
}

.nextBtn:disabled:hover:before {
  background-color: var(--secondary_color);
}

.nextBtn:disabled:hover:after {
  background-color: var(--secondary_color);
}

.tabHeader {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
}

.tabCount {
  background-color: var(--secondary_color);
  padding: 2px 14px;
  color: var(--primary_text_color);
  font-weight: 500;
  font-size: 14px;
  border-radius: 100px;
}

.tabCount span {
  color: var(--primary_button_color);
}

.skipButton {
  background-color: var(--primary_button_color);
  border-radius: 100px;
  padding: 2px 14px;
  color: var(--button_text_color);
  border: 0px;
}

.inputLabel {
  color: var(--heading_text_color);
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}

.smallInputWrapper {
  margin-bottom: 10px;
}

.formImage {
  width: 130px;
  height: 130px;
  margin-bottom: 40px;
  margin-top: 60px;
}
.contentViewRow {
  display: flex;
  margin: 0 -0.5rem;
}
.viewImage {
  width: 80%;
  margin-bottom: 0.75rem;
}
.selectedOption {
  border: 3px solid var(--primary_button_color) !important;
}

.selectedColorOption {
  border: 3px solid var(--primary_text_color) !important;
}
.mediaModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mediaModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}

.mediaModalHeader svg {
  color: #fff;
  opacity: 0.85;
  font-size: 15px;
  cursor: pointer;
}

.mediaModalBody p {
  color: var(--primary_text_color);
  text-align: start;
}

.mediaModalBody select {
  margin-top: 20px;
  width: 100%;
}

.mediaModalFooter button {
  background-color: var(--primary_button_color);
  border-radius: 100px;
  padding: 13px 54px;
  color: var(--button_text_color);
  border: 0px;
  width: 100%;
}

.imageUpload {
  border: 2px dashed var(--primary_text_color);
  padding: 45px 25px;
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.imageUpload svg {
  color: var(--primary_text_color);
  font-size: 36px;
}

.imageUpload span:first-of-type {
  margin-top: 15px;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
}

.imageUpload span:first-of-type span {
  color: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.imageUpload span:first-of-type span:hover {
  text-decoration: underline;
}

.imageUpload span:last-of-type {
  margin-top: 10px;
  color: var(--primary_text_color);
  font-weight: 400;
  font-size: 11px;
}

.btnWrapper {
  display: flex;
  justify-content: baseline;
  align-items: center;
  gap: 20px;
}

.cancelBtn {
  /* font-weight: 500; */
  /* line-height: 36px; */
  color: var(--error_color);
  border: 0;
  background: transparent;
}

.saveBtn {
  /* font-weight: 500; */
  border: 0;
  /* line-height: 36px; */
  color: var(--primary_button_color);
  background: transparent;
}

.cardWrapper {
  margin: 30px auto;
  background: var(--secondary_color);
  border-radius: 50px;
  width: 380px;
  padding: 30px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardWrapper p {
  margin-top: 45px;
  text-align: center;
  font-size: 14px;
}

.cardWrapper p span {
  font-size: 18px;
  line-height: 25px;
}

.cardIcon {
  margin-top: 60px;
  text-align: center;
}

.cardIcon img {
  width: 80px;
  height: 80px;
}

.formWrapper {
  margin-top: 60px;
  text-align: center;
}

/* .formWrapper select {
  transition: all 0.3s ease;
  width: 220px;
  height: 40px;
  font-size: 14px;
  border-radius: 50px;
  padding: 10;
} */
/* .formWrapper option:focus,
.formWrapper select:focus {
  border: 0;
  outline: none;
} */
/* .formWrapper option {
  color: var(--primary_button_color);
  font-size: 14px;
  border-radius: 50px;
} */
.preNext {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .preNext button {
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 48px;
  color: var(--primary_text_color);
} */
.preNext img {
  cursor: pointer;
}

.uploadBtnWrapper {
  margin-top: 80px;
  text-align: center;
}

.gridWrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  align-items: center;
  justify-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.gridWrapper img {
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 16.76px;
  border: 1px solid var(--border_color);
  object-fit: cover;
}

.colorDiv {
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 70px;
}

.emptyBackgroundImage {
  width: 70px;
  height: 70px;
  border: 2px dashed var(--border_color);
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyBackgroundImage span {
  font-size: 20px;
  font-weight: 600;
  color: var(--border_color);
}

.emptyBackgroundImage:hover {
  border-color: var(--primary_button_color);
  cursor: pointer;
}

.emptyBackgroundImage:hover span {
  color: var(--primary_button_color);
}

.emptyColor {
  width: 70px;
  height: 70px;
  border: 2px dashed var(--border_color);
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyColor span {
  color: var(--border_color);
  font-size: 36px;
  font-weight: 300;
}

.uploadBtn {
  background: var(--primary_button_color);
  border-radius: 68px;
  border: 0;
  color: var(--button_text_color);
  transition: all 0.3s ease;
  margin: 40px 0 40px;
  width: 160px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
}
.contentViewBox {
  border-radius: 20px;
  border: 2px solid var(--border_color);
  padding: 0.5rem;
}
.cardBodyWrapperBig .contentViewBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
}
.cardBodyWrapperBig .contentViewBox .viewImage {
  height: 30px;
  width: 30px;
  margin-right: 0.75rem;
  margin-bottom: 0;
}
.contentViewBox p {
  font-size: 14px;
}
.moreDetailWrapper {
  margin-bottom: 20px;
}

.moreDetailHeader {
  margin-bottom: 10px;
}

.moreDetailHeader svg {
  padding: 6px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: var(--border_color);
}

.moreDetailHeader span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
}

.textareaInput {
  /* margin-top: 20px; */
  width: 100%;
  height: 90px;
  background: var(--secondary_color);
  outline: none;
  color: var(--primary_text_color);
  border: 2px solid var(--primary_text_color);
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 20px;
}

.textareaInput::placeholder {
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}

.socialMediaLink {
  border: 2px solid var(--border_color);
  background-color: var(--secondary_color);
  border-radius: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 320px; */
  width: 100%;
  margin-bottom: 10px;
  height: 47.13px;
}

.socialMediaLink > :first-child {
  color: var(--primary_button_color);
  font-size: 15px;
}

.socialMediaLink span {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: var(--heading_text_color);
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.socialMediaModalLink {
  border: 2px solid var(--border_color);
  background-color: var(--secondary_color);
  border-radius: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  column-gap: 10px;
  height: 47.13px;
}

.socialMediaModalLinkIcon {
  font-weight: 800;
  text-transform: capitalize;
  font-size: 24px;
  color: var(--primary_button_color);
  width: 22px;
  text-align: center;
}

.socialMediaModalLinkIcon svg {
  width: 22px;
}

.socialMediaModalLinkIcon svg path {
  fill: var(--primary_button_color);
}

.socialMediaModalLink input {
  font-size: 12px;
  font-weight: 500;
  color: var(--heading_text_color);
  background-color: var(--secondary_color);
  border: 0;
  width: 100%;
  padding: 2px;
}

.socialMediaModalLink input:focus {
  border: 1px solid var(--border_color);
  outline: none;
  border-radius: 5px;
}

.socialLinkDeleteIcon {
  color: var(--error_color);
  font-size: 17px;
  cursor: pointer;
}

.successTitle {
  font-weight: 700;
  font-size: 24px;
  color: var(--primary_button_color);
}

.uploadImagesBtnWrapper {
  margin-top: 40px;
  text-align: center;
}

.colorsWrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 25px;
}

.picUploadBtnWrapper {
  text-align: center;
  margin-top: 30px;
}

.addUrlDiv {
  margin-top: 40px;
  position: relative;
}

.urlDeleteIcon {
  position: absolute;
  right: 0px;
  top: 3px;
  cursor: pointer;
}

.addUrlWrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
}

.socialIcon {
  min-width: 40px;
  min-height: 40px;
  background: var(--secondary_color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.socialIcon:hover {
  background: var(--primary_button_color);
}

.socialIcon img {
  max-width: 20px;
  max-height: 20px;
}

.socialInput {
  flex: 1;
}

.addUrlInput {
  background: transparent;
  border: 0;
  color: var(--primary_text_color);
  border-bottom: 2px solid var(--border_color);
  width: 100%;
  padding-right: 30px;
}

.addUrlInput:focus {
  outline: none;
}

.addUrlInput::placeholder {
  color: var(--primary_text_color);
}

.addMoreUrlBtn {
  text-align: center;
  margin-top: 50px;
}

.addMoreUrlBtn button {
  background: transparent;
  border: 0;
  color: var(--primary_button_color);
  outline: none;
}

.colorBox {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  cursor: pointer;
}

.addMoreUrls {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  justify-items: center;

  align-items: center;
}

.addMoreIcons {
  width: 50px;
  height: 50px;
  display: flex;
  background: var(--border_color);
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.addMoreIcons:hover {
  background: var(--primary_button_color);
  color: #bdbdbd;
}

.addMoreIcons svg {
  width: 20px;
  height: 20px;
}

.select {
  color: var(--primary_text_color);
  padding: 7px 12px;
  background: var(--secondary_color);
  border: 2px solid var(--primary_text_color);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  font-size: 14px;
}

.select option {
  font-weight: 500;

  line-height: 24px;

  color: var(--primary_text_color);
}

.select:focus,
.select option:focus {
  outline: none;
}

.customBackgroundWrapper {
  position: relative;
}

.bgImgClearBtnWrapper {
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bgImgClearBtn {
  cursor: pointer;
}

.loaderWrapper {
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileViewBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  display: none;
}

.mobileViewBtns button {
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary_color);
  transition: all 0.3s ease;
}

.mobilePrevBtn:disabled:hover {
  background-color: var(--secondary_color);
}

.mobileViewBtns button svg {
  color: var(--primary_text_color);
  width: 20px;
  height: 20px;
}

.mobileViewBtns button:hover {
  /* background-color: var(--primary_button_color); */
}

.addPhonutBtnWrapper {
  text-align: center;
}
.addPhonutBtnWrapper button {
  background: var(--primary_button_color);
  outline: none;
  width: 160px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  color: var(--button_text_color);
  border: 0;
  border-radius: 25px;
  margin-top: 20px;
}
.removePhonutBtnWrapper button svg {
  width: 25px;
  height: 25px;
  color: var(--error_color);
}
.removePhonutBtnWrapper {
  /* margin-bottom: 10px; */
  text-align: center;
}
.removePhonutBtnWrapper button {
  /* background: #e35252; */
  background: transparent;
  outline: none;
  border: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: var(--primary_text_color);
}
.phoneInputOuterWrapper {
  border: 2px solid var(--primary_text_color);
  border-radius: 25px;
  margin-top: 10px;
  padding: 10px;
  width: 370px;
}
.phoneInputMainWrapper {
  width: 350px;
}
.formInputWrapper {
  width: 350px;
  margin-top: 25px;
}

@media only screen and (max-width: 576px) {
  .mainWrapper {
    min-height: 0;
  }
  .tabHeader {
    font-size: 15px;
  }
  .formImage {
    width: 100px !important;
    height: 100px !important;
  }
  .cardBodyWrapper {
    max-width: 100%;
  }
  .nextBtn,
  .prevBtn {
    display: none;
  }
  .mobileViewBtns {
    display: flex;
  }
  .phoneInputOuterWrapper {
    width: 100%;
  }
  .formInputWrapper,
  .phoneInputMainWrapper {
    width: 100%;
  }
}
.social {
  border: 2px solid var(--primary_text_color);
  border-bottom: 0;
  /* border-radius: 20px; */
  margin-top: 1.5rem;
  overflow: hidden;
}
.socialMediaAddButton {
  color: var(--heading_text_color);
  padding: 7px 18px;
  background: var(--secondary_color);
  border-bottom: 2px solid var(--primary_text_color);
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.socialList {
  height: 200px;
  overflow: auto;
}
.socialData {
  color: var(--primary_text_color);
  padding: 7px 18px;
  background: var(--secondary_color);
  width: 100%;
  font-size: 14px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--primary_text_color);
}
.socialData p {
  cursor: pointer;
}
.arrow {
  font-size: 26px;
}
/* @media only screen and (max-width: 420px) {
  .formInputWrapper
 , .phoneInputMainWrapper {
    width: 100%;
  }
} */
@media only screen and (max-width: 375px) {
  .socialMediaLink span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
