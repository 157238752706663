.mainWrapper {
  margin-top: 50px;
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.boxWrapper {
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 100px 20px 40px;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  position: relative;
}

.priorityBtn {
  background-color: var(--accent_color);
  color: var(--heading_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;
  align-items: center;
}
.priorityBtn svg {
  width: 18px;
  margin-right: 5px;
}
.priorityBtn span {
  font-size: 13px;
  line-height: 1;
}
.priorityModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}
.priorityModalBody label {
  font-size: 11px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}
.priorityModalBody .customSelect.form-select {
  background: var(--secondary_color);
  border-left: 0;
  border-radius: 60px;
  box-shadow: none;
  font-size: 14px;
  line-height: 100%;
  padding: 9px 10px;
  color: var(--heading_text_color);
  border: 0;
}
.customModal .priorityModalBody .errorText {
  display: none;
}
.saveBtn {
  background-color: var(--primary_button_color);
  color: var(--button_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .boxWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  .boxWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
