.main {
  width: calc(100% - 228px);
  background-color: var(--primary_color);
  min-height: 100vh;
  color: var(--heading_text_color);
  transition: all 0.3s ease;
  padding-bottom: 50px;
}
.collapsedMain {
  width: calc(100% - 74px);
  background-color: var(--primary_color);
  min-height: 100vh;
  color: var(--primary_text_color);
  transition: all 0.3s ease;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
  }
  .collapsedMain {
    width: 100%;
  }
}
