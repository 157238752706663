.mainWrapper {
  margin-top: 50px;
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}
.content {
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 40px 20px;
  margin: 20px 0px;
}
.pageSubTitle {
  color: var(--primary_text_color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.boxWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.inputSelect {
  display: flex;
}
.features {
  background-color: var(--secondary_Color);
  padding: 20px;
  border-radius: 20px;
  margin-top: 40px;
}
.features p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.table {
  border: 1px solid var(--border_color);
  border-radius: 20px;
  vertical-align: middle;
}
.table td,
.table th {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  padding: 0.5rem 1rem;
}
.table td:not(:last-child) {
  border-bottom-color: var(--border_color);
}
.table th {
  color: #49556d;
  padding: 1rem;
}
.table td.quantity > div {
  width: 20%;
  display: inline-block;
}

@media only screen and (max-width: 1024px) {
  .boxWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .table td,
  .table th {
    font-size: 12px;
  }
}
@media only screen and (max-width: 576px) {
  .boxWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
