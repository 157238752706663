.profileWrapper {
  margin: 30px 15px;
}
.title {
  /* font-weight: 500; */
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 20px;
}
.label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}
.info {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.hr {
  color: var(--primary_text_color);
}
.nameWrapper {
  display: flex;
  align-items: center;
  gap: 100px;
}

.bioDataWrapper {
  padding: 0 25px;
}
.bioDataWrapper h6 {
  font-weight: 500;
  line-height: 24px;
  color: var(--primary_text_color);
}
.bioData {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.deleteBtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: 0;
  color: var(--primary_text_color);
  background: transparent;
}
.updateBtn {
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: transparent;
  color: var(--primary_button_color);
}
.address {
  display: flex;
  align-items: center;
  gap: 180px;
}
.email {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 180px;
}
.dob {
  margin-top: 10px;
}
.socials {
  margin-top: 10px;
}
.socials h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--primary_text_color);
}
.socialInputWrapper {
  /* display: flex;
  align-items: center;
    flex-wrap: wrap; */
  max-width: 620px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 50px;
  row-gap: 20px;
}

.selectWrapper {
  margin-top: 0px;
}
.selectWrapper label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}

.submitBtnWrapper {
  margin-top: 30px;
}
.submitBtnWrapper button {
  background: var(--primary_button_color);
  border: 0;
  color: var(--button_text_color);
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
@media only screen and (max-width: 768px) {
  .bioDataWrapper {
    padding: 0 0;
  }
}
