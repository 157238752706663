.page {
  background: #1e1e1e;
}
.mainWrapper {
  max-width: 360px;
  margin: 25px auto;
  margin-top: 50px;
}
.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}
.subHeading {
  font-size: 14px;
  line-height: 100%;
  color: #535f79;
}
.boxWrapper {
  width: 360px;
  margin-top: 20px;
  background: #21293b;
  border-radius: 20px;
  padding: 30px 20px;
  border-radius: 20px;
}
.info {
  margin-top: 25px;
  font-size: 14px;
  color: #535f79;
}
.submitBtnWrapper {
  margin-top: 20px;
}
.submitBtn {
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 50px;
  color: #fff;
  background: var(--primary_button_color);
}
.submitBtn:hover {
  /* opacity: 0.7; */
  color: #fff;
}
.forgotPasswordWrapper {
  text-align: center;
  margin-top: 20px;
}
.forgotPasswordWrapper a {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_button_color);
}

.dot {
  height: 150px;
  width: 150px;
  background-color: var(--primary_button_color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 100px;
  color: var(--primary_text_color);
}

.successFooter {
  padding-top: 25px;
  color: var(--primary_text_color);
  font-weight: 600;
  text-align: center;
}
.successFooter span {
  font-size: 20px;
  color: var(--primary_button_color);
}

@media only screen and (max-width: 768px) {
  .heading {
    font-weight: 600;
    font-size: 22px;
  }
}
@media only screen and (max-width: 576px) {
  .heading {
    font-weight: 500;
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .mainWrapper {
    max-width: 300px;
  }
  .boxWrapper {
    width: 300px;
  }
}
