.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.modalHeading {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.btnWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.saveBtn {
  font-weight: 600;
  font-size: 14px;
  color: var(--button_text_color);
  height: 35px;
  border-radius: 10px;
  width: 100%;
  background-color: var(--primary_button_color);
  border: none;
  white-space: nowrap;
}

.cancelBtn {
  font-weight: 600;
  font-size: 14px;
  color: var(--button_text_color);
  height: 35px;
  border-radius: 10px;
  width: 100%;
  background-color: var(--error_color);
  border: none;
  white-space: nowrap;
}

.cancelBtn:hover {
  background-color: var(--error_color) !important;
}

.label {
  font-size: 10px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}

.savePhonebookBtnWrapper button {
  color: var(--primary_text_color);
  border: 0;
  border-radius: 50px;
  height: 40px;
  width: 160px;
  font-weight: 600;
  font-size: 14px;
  background: var(--primary_button_color);
  margin-top: -10px;
}

.savePhonebookBtnWrapper button span {
  margin-right: 5px;
}
.savePhonebookBtnWrapper button span img {
  width: 20px;
  height: 20px;
}

.payBtn {
  border-radius: 50px;
  border: 0;
  background: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  width: 252px;
  height: 44px;
  transition: all 0.3s ease;
}
.payBtn:hover {
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 5.3928px 17.976px rgba(34, 160, 129, 0.4);
}

.autoCheckbox {
  margin-top: 15px;
  margin-left: 5px;
  color: #fff;
}

.bottomDetail {
  margin: 20px 4px;
}

.bottomDetail p {
  color: #fff;
}

.mediaModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mediaModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}

.mediaModalHeader svg {
  color: #fff;
  opacity: 0.85;
  font-size: 15px;
  cursor: pointer;
}

.mediaModalBody p {
  color: var(--primary_text_color);
  text-align: start;
}

.mediaModalBody select {
  margin-top: 20px;
  width: 100%;
}

.mediaModalFooter button {
  background-color: var(--primary_button_color);
  border-radius: 100px;
  padding: 13px 54px;
  color: var(--button_text_color);
  border: 0px;
  width: 100%;
}

.imageUpload {
  border: 2px dashed var(--primary_text_color);
  padding: 45px 25px;
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.imageUpload svg {
  color: var(--primary_text_color);
  font-size: 36px;
}

.imageUpload span:first-of-type {
  margin-top: 15px;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
}

.imageUpload span:first-of-type span {
  color: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.imageUpload span:first-of-type span:hover {
  text-decoration: underline;
}

.imageUpload span:last-of-type {
  margin-top: 10px;
  color: var(--primary_text_color);
  font-weight: 400;
  font-size: 11px;
}
