.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--heading_text_color);
  text-align: center;
  margin-top: 30px;
}

.subHeading {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-bottom: 30px;
  text-align: center;
  margin-top: 10px;
}

.mainWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  width: 100%;
  padding: 30px 15px;
}
.cardsWrapper {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(0, 300px)); */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* align-items: center; */
  gap: 10px;
}
.card {
  background: var(--tertiary_color);
  box-shadow: 6.94286px 8.48571px 13.8857px rgba(25, 32, 46, 0.5);
  border-radius: 15.4286px;
  padding-top: 30px;
  text-align: center;
}
.subscriptionType {
  color: var(--button_text_color);
  background: var(--primary_button_color);
  opacity: 0.7;
  outline: none;
  border: 0;
  padding: 4.5px 25px;
  border-radius: 40px;
}
.imgWrapper {
  width: 70px;
  height: 70px;
  /* background: var(--primary_button_color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center; */
  margin: auto;
  margin-top: 25px;
}
.imgWrapper img {
  width: 100%;
  height: 100%;
}
.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-top: 20px;
}
.title span {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.description {
  font-size: 11px;
  line-height: 16px;
  color: var(--primary_text_color);
  text-align: center;
  padding: 10px 40px;
}
.btnWrapper {
  padding: 30px 0px;
  margin-top: 30px;
  border-top: 1px solid var(--border_color);
}
.payBtn,
.btn {
  border-radius: 50px;
  border: 0;
  background: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--button_text_color);
  width: 252px;
  height: 44px;
  transition: all 0.3s ease;
}
.btn:hover {
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 5.3928px 17.976px rgba(34, 160, 129, 0.4);
}
.outlineBtn {
  border-radius: 50px;
  background: transparent;
  border: 2px solid var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_button_color);
  width: 252px;
  height: 44px;
  transition: all 0.3s ease;
}
.outlineBtn:hover {
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 5.3928px 17.976px rgba(34, 160, 129, 0.4);
  color: var(--button_text_color);
}
.list {
  list-style: initial;
  padding-right: 20px;
  padding-left: 30px;
  margin-top: 20px;
  line-height: 2;
  color: var(--heading_text_color);
}
.list p {
  font-size: 14px;
}
.tabsWrapper {
  margin-top: 20px;
  margin-inline: 20px;
}

.imagesWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid var(--border_color);
  padding: 20px 0;
  margin-top: 10px;
  margin-left: 10px;
}

.autoCheckbox {
  margin-top: 15px;
  margin-left: 5px;
  color: #fff;
}

.skipBtnWrapper {
  margin-top: 30px;
}
.skipBtnWrapper button {
  outline: none;
  border: 0;
  border-radius: 10px;
  border: 0;
  background: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--heading_text_color);
  width: 100px;
  height: 44px;
  transition: all 0.3s ease;
}
.couponWrapper {
  background-color: var(--secondary_color);
  border-radius: 10px;
  padding: 15px 25px;
  margin-top: 30px;
}
@media only screen and (max-width: 1399.98px) {
  .list p {
    font-size: 13px;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .cardsWrapper {
    /* grid-template-columns: repeat(2, 1fr); */
  }
  .title {
    font-weight: bold;
    font-size: 22px;
  }
}
@media only screen and (max-width: 576px) {
  .cardsWrapper {
    /* grid-template-columns: auto; */
  }
}
