:root {
  --darkblue: #21293b;
  --light_grey: #232c3d;
  --dark: #333e56;
  --black: #1e1e1e;
  --gray: #535f79;
  --white: var(--primary_text_color);
  --aqua: #22a081;
  --aqua_gradient: linear-gradient(106.6deg, #76e2c6 0%, #22a081 99.84%);
}

.footer {
  position: relative;
  z-index: 26;
  background: var(--secondary_color);
  border-radius: 20px 20px 0px 0px;
  margin-top: -50px;
}

.footerContentWrapper {
  padding: 0 100px;
}

.footer p {
  line-height: 24px;
  color: var(--primary_text_color);
  margin-top: 30px;
}

.social_icons {
  margin-top: 30px;
  gap: 20px;
}

.avatarIcon {
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: var(--social_bg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.avatarIcon img {
  width: 20px;
  height: 20px;
}

.avatarIcon:hover {
  background: var(--aqua_gradient);
}

.footer h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--heading_text_color);
  margin-bottom: 3px;
  white-space: nowrap;
}

.footer a {
  white-space: nowrap;
  line-height: 24px;
  color: var(--primary_text_color);
  transition: 0.5s ease;
}

.footer li a:hover {
  color: var(--heading_text_color);
}

.app_stores {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.app_stores img {
  width: 100%;
}

.rights {
  margin-top: 25px;
  padding-top: 13px;
  line-height: 24px;
  color: var(--primary_text_color);
  border-top: 1px solid var(--border_color);
}

/* .app_stores {
  position: absolute;
  top: 150px;
  right: 35px;
} */
/* @media only screen and (max-width: 1024px) {
  .app_stores {
    display: block;
    gap: 0px;
  }
} */

@media only screen and (max-width: 768px) {
  .footer {
    margin-top: -30px;
    z-index: 0;
  }

  .footerContentWrapper {
    padding: 0 50px;
  }

  .footer p {
    margin-top: 20px;
  }
  .footer h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .social_icons {
    margin-top: 20px;
    gap: 15px;
  }

  .footer a {
    line-height: 30px;
  }

  .app_stores {
    gap: 5px;
  }
}

@media only screen and (max-width: 576px) {
  .footerContentWrapper {
    padding: 0 20px;
  }

  .footer p {
    margin-top: 20px;
  }
  .footer h6 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
  }

  .social_icons {
    margin-top: 20px;
    gap: 15px;
  }

  .avatarIcon {
    width: 40px;
    height: 40px;
  }

  .avatarIcon img {
    width: 12px;
    height: 16px;
  }
  .footer a {
    line-height: 35px;
    font-size: 14px;
  }

  .app_stores {
    gap: 5px;
  }
}
