.mainWrapper {
  margin-top: 20px;
}
.outerWrapper {
  padding: 20px;
}
.label {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}
.checkboxBtn,
.checkboxBtn:hover,
.checkboxBtn:disabled {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin-top: 1rem;
}
.checkboxBtn label {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.checkboxBtn label input {
  width: 1.5em;
  height: 1.5em;
}
