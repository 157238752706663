.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--primary_text_color);
}

.mainWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 30px 30px;
}
.addBtn {
  color: var(--primary_text_color);
  background: var(--primary_button_color);
  padding: 5px 10px;
  border-radius: 5px;
}
.addBtn:hover {
  color: var(--primary_text_color);
}
.listHeadingsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0px 20px;
  /* display: flex;
  align-items: center;
  gap: 100px;
  padding: 0px 20px; */
}
.listHeadingsWrapper p {
  text-transform: capitalize;
  font-weight: 600;
  line-height: 100%;
  color: var(--primary_text_color);
}

/* .listHeadingsWrapper p:nth-child(2) {
  flex: 1;
} */
.listHeadingsWrapper p:nth-child(3) {
  text-align: right;
}
.description {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.listDeatailsWrapper {
  margin-top: 20px;
  /* display: flex;
  align-items: center;
  gap: 90px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  background: var(--tertiary_color);
  padding: 20px 20px;
  border: 2px solid var(--border_color);
  border-radius: 20px;
  transition: all 0.3s ease;
}
.listDeatailsWrapper:hover {
  border: 2px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}
/* .listDeatailsWrapper p:nth-child(2) {
  flex: 1;
} */
.actionsWrapper {
  text-align: right;
  margin-right: 15px;
}
.listDeatailsWrapper p {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--primary_text_color);
}
