:root {
  --darkblue: #21293b;
  --light_grey: #232c3d;
  --dark: #333e56;
  --black: #1e1e1e;
  --gray: #535f79;
  --white: #ffffff;
  --aqua: #22a081;
  --aqua_gradient: linear-gradient(106.6deg, #76e2c6 0%, #22a081 99.84%);
}

.page {
  background-color: var(--black);
}
.navbar {
  padding-top: 30px;
}
.logo {
  width: 111px;
  height: 24px;
}
.list {
  display: flex;
  align-items: center;
  gap: 15px;
}
.signBtn {
  border: 2px solid var(--aqua);
  border-radius: 50px;
  color: var(--aqua);
  width: 85px;
  height: 34px;
  font-size: 14px;
  line-height: 21px;
  transition: all 0.5s ease;
  background: transparent;
  white-space: nowrap;
}
.signBtn:hover {
  color: var(--white);
  background-color: var(--aqua);
}

.accountWrapper {
  max-width: 360px;
  margin: 25px auto;
}

.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}
.subHeading {
  font-size: 14px;
  line-height: 100%;
  color: #535f79;
}
.createAccountBox {
  width: 360px;
  margin-top: 20px;
  background: #21293b;
  border-radius: 20px;
  padding: 30px 20px;
  border-radius: 20px;
}
.inputsGap {
  margin-top: 20px;
}
.forgotPasswordWrapper {
  text-align: right;
  margin-top: 10px;
}
.forgotPasswordWrapper a {
  font-size: 12px;
  line-height: 21px;
  color: #22a081;
}
.submitBtnWrapper {
  margin-top: 20px;
}
.submitBtn {
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(106.6deg, #76e2c6 0%, #22a081 99.84%);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 68px;
}
.submitBtn:hover {
  /* opacity: 0.7; */
  color: #fff;
}

.moreSignOptions {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #535f79;
  margin-top: 30px;
  text-align: center;
}

.moreSignOptions::before {
  content: "";
  width: 70px;
  height: 1px;
  display: inline-block;
  background: #535f79;
  margin: 5px 30px 5px 0;
}

.moreSignOptions::after {
  content: "";
  width: 70px;
  height: 1px;
  display: inline-block;
  background: #535f79;
  margin: 5px 0px 5px 30px;
}

.socialIconsWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.iconAvatar {
  cursor: pointer;
  width: 44px;
  height: 44px;
  background-color: #10151b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.iconAvatar:hover {
  background: var(--aqua_gradient);
}
.iconAvatar button {
  background: transparent;
  border: 0;
  outline: none;
}
.iconAvatar img {
  width: 20px;
  height: 20px;
}
.notAccountWrapper {
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.notAccountWrapper p {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #535f79;
}
.notAccountWrapper a {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #22a081;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 150px;
    height: 35px;
  }

  .heading {
    font-weight: 600;
    font-size: 22px;
  }
  .moreSignOptions {
    font-weight: 500;
  }

  .moreSignOptions::before {
    margin: 5px 25px 5px 0;
  }

  .moreSignOptions::after {
    margin: 5px 0px 5px 25px;
  }

  .notAccountWrapper p {
    font-weight: 500;
  }
  .notAccountWrapper a {
    font-weight: 500;
  }

  .iconAvatar {
    width: 40px;
    height: 40px;
  }

  .iconAvatar img {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    width: 130px;
    height: 30px;
  }

  .heading {
    font-weight: 500;
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .logo {
    width: 110px;
    height: 30px;
  }
  .signBtn {
    width: 75px;
  }
  .accountWrapper {
    max-width: 300px;
  }
  .createAccountBox {
    width: 300px;
    padding: 30px 15px;
  }

  .moreSignOptions {
    font-weight: 400;
  }
  .moreSignOptions::before {
    width: 60px;
    margin: 5px 10px 5px 0;
  }

  .moreSignOptions::after {
    width: 60px;
    margin: 5px 0px 5px 10px;
  }
}
