.title {
  font-weight: 700;
  line-height: 100%;
  color: var(--primary_text_color);
}
.cardsWrapper {
  background: var(--secondary_color);
  padding: 40px 24px;
  margin: 18px 0px;
  border-radius: 20px;
}

.boxWrapper {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 30px;
  overflow-y: auto;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}

.boxWrapper::-webkit-scrollbar {
  display: none;
  width: 5px;
  height: 3px;
}
.alertIcon svg {
  color: #ff0000;
  font-size: 50px;
  margin-bottom: 0.5rem;
}
.alertIcon p {
  line-height: 1.2;
  font-size: 13px;
}
.alertIcon p.modTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
  margin-bottom: 1rem;
}
/* Track */
.boxWrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: var(--white);
}

/* Handle */
.boxWrapper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: var(--dark);
}

.infoWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 15px;
  margin-top: 70px;
  position: relative;
}
.infoRemoveBtn {
  position: absolute;
  top: -20px;
  right: 20px;
  cursor: pointer;
}

.romoveInfoMainWrapper {
  display: none;
}

.arrowImgWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 200px;
}
.arrowImg {
  margin-inline: 20px;
  flex: 0 0 40%;
  max-width: 40%;
}
.createBtnWrapper {
  flex: 1;
}
.arrowImg img {
  width: 100%;
}
/* .arrowImage {
  position: absolute;
  width: 370px;
  top: 75px;
  left: 55px;
}
@media only screen and (min-width: 375px) and (max-width: 768px) {
  .arrowImage {
    width: 230px;
    position: absolute;
    top: 96px;
  }
  .createBtnWrapper {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .arrowImage {
    width: 245px;
    position: absolute;
    top: 96px;
  }
  .createBtnWrapper {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1325px) and (max-width: 1440px) {
  .arrowImage {
    width: 455px;
    position: absolute;
    top: 75px;
  }
  .createBtnWrapper {
    margin-top: 110px;
  }
} */
.priorityBtn {
  background-color: var(--accent_color);
  color: var(--heading_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;
  align-items: center;
}
.priorityBtn svg {
  width: 18px;
  margin-right: 5px;
}
.priorityBtn span {
  font-size: 13px;
  line-height: 1;
}
.priorityModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading_text_color);
}
.priorityModalBody label {
  font-size: 11px;
  line-height: 100%;
  color: var(--heading_text_color);
  margin-bottom: 10px;
}
.priorityModalBody .customSelect.form-select {
  background: var(--secondary_color);
  border-left: 0;
  border-radius: 60px;
  box-shadow: none;
  font-size: 14px;
  line-height: 100%;
  padding: 9px 10px;
  color: var(--heading_text_color);
  border: 0;
}
.customModal .priorityModalBody .errorText {
  display: none;
}
.saveBtn {
  background-color: var(--primary_button_color);
  color: var(--button_text_color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
}
.createBtnWrapper a {
  white-space: nowrap;
  padding: 10px 20px;
  background: linear-gradient(
    106.6deg,
    var(--primary_button_color_light) 0%,
    var(--primary_button_color) 99.84%
  );
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
  border-radius: 50px;
  color: var(--primary_text_color);
  transition: 0.3s all ease;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.createBtnWrapper a:hover {
  opacity: 0.7;
}
.contentWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.contentWrapper img {
  width: 28px;
  height: 28px;
}
.contentWrapper h6 {
  font-weight: 600;
  line-height: 24px;
  color: var(--primary_text_color);
  padding-bottom: 10px;
}
.contentWrapper p {
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  padding-bottom: 10px;
}

.linkViewAllWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.linkViewAll {
  padding: 6px 10px;
  background: var(--secondary_Color);
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary_text_color);
  text-transform: capitalize;
}
.linkViewAll span {
  margin-left: 15px;
}
.linkViewAll:hover {
  color: var(--primary_text_color);
  opacity: 0.7;
}

/* .customUrlsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
} */

/* Emergency Contact Section */
.emergencyContentWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  padding: 30px 20px;
  border-radius: 20px;
}
.emergencyContactCardsWrapper,
.customURSLCardsWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.linkViewAllWrapper p,
.totalBusinessCardsTitle {
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
}

/* Custom URLS CSS */
.customURLSWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addContentWrapper {
  margin-top: 20px;
  padding: 10px 0px;
  border: 2px dashed var(--border_color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--primary_text_color);
  font-size: 36px;
}
.addContentWrapper:hover {
  background: var(--border_color);
}
.addContentWrapper a {
  text-align: center;
}
.addContentWrapper p {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--primary_text_color);
  margin-top: 8px;
}

.arrowBtn {
  width: 30px;
  height: 30px;
  background-color: var(--secondary_Color);
  color: var(--primary_text_color);
  border-radius: 10px;
  padding: 4px;
  margin-left: 15px;
  cursor: pointer;
}
.heroSectionWrapper {
  display: flex;
}

.arrowBtn:hover {
  color: var(--primary_text_color);
  opacity: 0.7;
}
.noFunctionsCreated {
  background-color: var(--secondary_color);
  color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noFunctionsCreated h6 {
  font-weight: 700;
  line-height: 100%;
}
.noRecord {
  min-height: 85px;
  border: 2px dashed var(--border_color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease;
  text-align: center;
}
.noCards {
  min-height: 308px;
  width: 212px;
  border: 2px dashed var(--border_color);
  border-radius: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease;
}
.noCards:hover,
.noRecord:hover {
  background: var(--border_color);
}
.navbarSection {
  background: var(--secondary_color);
  /* max-width: 320px;
  margin: auto; */
  /* border-radius: 20px; */
  /* margin-top: 20px; */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 40px; */
}
.personProfile h6 {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary_text_color);
  text-align: center;
  /* margin-top: 20px; */
}
.personProfile p {
  color: var(--primary_text_color);
  font-size: 12px;
  text-align: center;
}
.profileImgeWrapper {
  text-align: center;
}
.profileImgeWrapper img {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

@media only screen and (max-width: 768px) {
  .emergencyContactCardsWrapper,
  .customURSLCardsWrapper {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 576px) {
  .profileImgeWrapper {
    position: initial;
  }
  .profileImgeWrapper img {
    object-fit: cover;
    width: 50px;
    height: 50px;
  }
  .navbarSection {
    padding: 10px 0px;
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 340px) {
  .emergencyContactCardsWrapper,
  .customURSLCardsWrapper {
    grid-template-columns: auto;
    justify-content: center;
  }
}
/* 
.mainWrapper {
  margin-top: 50px;
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.boxWrapper {
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 40px 20px;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

@media only screen and (max-width: 1024px) {
  .boxWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  .boxWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
} */

/* Functions page css */
.mainWrapper {
  margin-top: 50px;
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--heading_text_color);
}

.boxWrapper {
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 100px 20px;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

@media only screen and (max-width: 1024px) {
  .boxWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  .boxWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
