.gridView {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  gap: 20px;
  padding: 40px 0px;
  margin: 0 20px;
}
.gridViewCard {
  border: 2px solid var(--border_color);
  border-radius: 20px;
  transition: all 0.3s ease;
  padding: 20px 0px;
  position: relative;
  max-width: 180px;
  min-height: 180px;
}
.gridViewCard:hover {
  border: 2px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}
.action {
  position: absolute;
  top: 10px;
  right: 10px;
}
.actionIcon {
  color: var(--primary_text_color);
}
.gridViewContentWrapper {
  text-align: center;
}
.gridViewContentWrapper img {
  border: 2px solid var(--primary_text_color);
}
.gridViewContent {
  margin: 20px 20px 0 20px;
}
.gridViewContent h6 {
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  overflow: hidden;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--primary_text_color);
  display: block;
}
.gridViewContent p {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: capitalize;
  color: var(--primary_text_color);
}

.gridViewCard:hover .gridViewContent p,
.gridViewCard:hover .actionIcon {
  color: var(--primary_text_color);
}

.modalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.modalHeader svg {
  color: var(--primary_text_color);
  opacity: 0.85;
  font-size: 22px;
  cursor: pointer;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 60px;
  border: 2px solid var(--border_color);
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.linkWrapper svg {
  color: var(--primary_text_color);
  font-size: 20px;
  cursor: pointer;
}

.linkWrapper span {
  overflow: hidden;
  color: var(--primary_text_color);
  font-size: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  /* .gridView {
    grid-template-columns: repeat(3, 1fr);
  } */
}

@media only screen and (max-width: 576px) {
  .gridView {
    /* justify-items: center; */
  }
}
