.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--primary_text_color);
}

.boxWrapper {
  margin-top: 20px;
  background: var(--secondary_color);
  border-radius: 20px;
  padding: 40px 0px 20px 0px;
  overflow: auto;
  min-height: 100vh;
}
.mainWrapper {
  padding: 0 20px;
}

.statsBoxWrapper {
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  /* width: 320px; */
  width: 33.3333%;
  gap: 20px;
  background: var(--secondary_color);
  transition: all 0.3s ease;
  cursor: pointer;
}
.statsBoxWrapper:hover {
  background: var(--border_color);
}
.counter {
  color: var(--primary_text_color);
  font-weight: 900;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
}
.totalTite {
  color: var(--primary_text_color);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  margin-top: 10px;
}
