.notificationHeader {
  background: var(--accent_color);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0px 0px;
  padding: 0 20px;
}
.notificationHeader img {
  cursor: pointer;
}
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--heading_text_color);
  /* margin-left: 20px; */
}
.contentWrapper {
  margin: 10px 16px;
}
.date {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary_text_color);
  margin-inline: 6px;
}
.notificationsMainWrapper {
  position: absolute;
  top: 35px;
  right: 0;
  background: var(--secondary_color);
  border-radius: 20px;
  width: 360px;
  height: 400px;
  padding: 0;
  overflow: auto;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}
.notificationsWrapper {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  /* overflow: auto; */
}
.notificationTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--heading_text_color);
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notificationDetail {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
  margin-top: 1px;
  margin-left: 30px;
  word-wrap: break-word;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40%;
}
.noRecordFound p {
  margin: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid var(--primary_button_color);
  border-bottom: 3px solid var(--primary_button_color);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.clearBtn {
  font-size: 11px;
  font-weight: 500;
  color: var(--heading_text_color);
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--border_color);
  padding: 0;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 576px) {
  .notificationsMainWrapper {
    right: -350%;
    width: 300px;
  }
  .notificationTitle {
    max-width: 200px;
  }
}
@media only screen and (max-width: 320px) {
  .notificationsMainWrapper {
    width: 280px;
  }
  .notificationTitle {
    max-width: 180px;
  }
}
