.notificationWrapper {
  /* margin: 30px 15px; */
}
.title {
  font-weight: 500;
  line-height: 100%;
  color: var(--heading_text_color);
}
.info {
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary_text_color);
}
.hr {
  color: var(--primary_text_color);
}
.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}
.emailTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--heading_text_color);
}
.inputWrapper {
  margin-top: 10px;
  /* padding: 0 25px; */
  padding: 0 10px;
}
.submitBtnWrapper {
  margin-top: 30px;
  padding-top: 20px;
}
.submitBtnWrapper button {
  background: var(--primary_button_color);
  border: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--button_text_color);
  transition: all 0.3s ease;
  padding: 10px 30px;
  border-radius: 50px;
}
/* .submitBtnWrapper button:hover {
  background: linear-gradient(106.6deg, var(--primary_button_color_light) 0%, var(--primary_button_color) 99.84%);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
} */
@media only screen and (max-width: 768px) {
  .inputWrapper {
    padding: 0 0;
  }
}
