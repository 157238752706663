.helpHeader {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border_color);
}

.contentWrapper {
  background: var(--secondary_Color);
  border-radius: 20px;
  padding: 20px;
  margin: 0 120px;
}
.detail {
  font-size: 14px;
  color: var(--primary_text_color);
  margin-top: 10px;
}
.detail h1,
.detail h2,
.detail h3,
.detail h4,
.detail h5,
.detail h6,
.detail strong {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .contentWrapper {
    margin: 0;
  }
}
