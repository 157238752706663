.helpHeader {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border_color);
}
.helpImage {
  width: 100%;
  height: 275px;
  background-image: url("../../../images/help/help_home.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.btnsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.btnsWrapper button {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--secondary_color);
  color: var(--primary_button_color);
  border: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-top: 20px;
  transition: all 0.3s ease;
}
.btnsWrapper button:hover {
  background: var(--primary_button_color);
  color: var(--primary_text_color);
}

/* 2ND SECTION */
.helpbottom {
  margin-top: 50px;
}
.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 20px;
}
.aboutIcon {
  width: 36px;
}
.card {
  background: var(--tertiary_color);
  border: 2px solid var(--border_color);
  border-radius: 20px;
  transition: all 0.3s ease;
  max-width: 160px;
  height: 178px;
}
.card:hover {
  background: var(--border_color);
  border: 2px solid var(--primary_button_color);
  box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
}
.card:hover svg path {
  fill: var(--primary_button_color);
}
.contentWrapper {
  padding: 25px 20px 0px 20px;
}
.card:hover .contentWrapper svg {
  color: var(--primary_button_color);
}
.mainIcon {
  font-size: 36px;
}
.contentWrapper h6 {
  margin-top: 30px;
  font-weight: 600;
  line-height: 100%;
  min-height: 30px;
  color: var(--primary_text_color);
}
.arrowIconWrraper {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
}
.arrowIconWrraper p {
  color: var(--primary_button_color);
  font-size: 11px;
  line-height: 100%;
}
.arrowIconWrraper svg {
  color: var(--primary_button_color);
  min-width: 15px;
  min-height: 15px;
}

@media screen and (min-width: 1320) {
  .card {
    background: var(--tertiary_color);
    border: 2px solid var(--border_color);
    border-radius: 20px;
    transition: all 0.3s ease;
    max-width: 160px;
    height: 178px;
  }
}

@media only screen and (max-width: 768px) {
  .btnsWrapper {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .cardsWrapper {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  .card {
    width: 160px;
  }
}
@media only screen and (max-width: 375px) {
  .card {
    width: 150px;
  }
}
