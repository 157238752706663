.qrWrapper {
  margin-bottom: 30px;
  position: relative;
}

.qrWrapper .qrBorder {
  position: absolute;
  top: -35px;
  left: -42px;
}

.qrWrapper canvas {
  border-radius: 10px;
}
.qrCodeImage {
  border-radius: 10px;
  top: -10px;
  width: 170px;
}
.qrTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary_text_color);
  margin-bottom: 10px;
}

.shareBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  /* width: 96px;
  height: 40px; */
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 0.5rem 0.75rem;
}

.shareBtn svg {
  font-size: 22px;
  margin-right: 5px;
}

.btnWrapper {
  margin-top: 25px;
}
.editBtn {
  margin-top: 15px;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 0.5rem 0.75rem;
}
.uploadBtn {
  /* background: var(--primary_button_color);
  padding: 8px 14px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary_text_color);
  border: 0px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 0.5rem; */
  background-color: transparent;
  border: 0;
}
.removeImgBtn {
  /* background-color: var(--secondary_Color);
  padding: 8px 14px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary_text_color);
  border: 0px;
  border-radius: 10px;
  margin-top: 10px; */
  background-color: transparent;
  border: 0;
}
.saveBtn {
  margin: 1.5rem 0 2.5rem;
  border: 2px solid var(--primary_button_color);
  color: var(--primary_button_color);
  background-color: var(--secondary_color);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 6px 14px;
}
.colorPicker[type="color"] {
  border: 2px solid var(--primary_button_color);
  outline: none;
  block-size: 16px;
  padding: 0;
  inline-size: 16px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  background: transparent;
}
/* .colorPicker[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
} */
.colorPicker[type="color"]::-webkit-color-swatch {
  border-radius: 0;
  padding: 0;
  border: 0;
}
.qrEditFooter {
  position: absolute;
  top: 10px;
  right: -10%;
  background-color: var(--secondary_color);
  padding: 15px 5px 15px 5px !important;
  border-radius: 0 !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.qrEditFooter li img {
  width: 16px;
}

.mediaModalHeader span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary_text_color);
}

.mediaModalHeader svg {
  color: var(--primary_text_color);
  font-size: 15px;
  cursor: pointer;
}

.mediaModalBody p {
  color: var(--primary_text_color);
  text-align: start;
}

.mediaModalBody select {
  margin-top: 20px;
  width: 100%;
}

.mediaModalFooter button {
  background-color: var(--primary_button_color);
  border-radius: 100px;
  padding: 13px 54px;
  color: var(--primary_text_color);
  border: 0px;
  width: 100%;
}

.imageUpload {
  border: 2px dashed var(--primary_text_color);
  padding: 45px 25px;
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.imageUpload svg {
  color: var(--primary_text_color);
  font-size: 36px;
}

.imageUpload span:first-of-type {
  margin-top: 15px;
  color: var(--primary_text_color);
  font-weight: 600;
  font-size: 14px;
}

.imageUpload span:first-of-type span {
  color: var(--primary_button_color);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.imageUpload span:first-of-type span:hover {
  text-decoration: underline;
}

.imageUpload span:last-of-type {
  margin-top: 10px;
  color: var(--primary_text_color);
  font-weight: 400;
  font-size: 11px;
}
.QRCodeModal {
}
